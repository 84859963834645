import { CANCELED_API_REQ } from '@commutifi-fe/constants'
import { CommutifiErrorData } from '@commutifi-fe/interfaces'
import Axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse, CreateAxiosDefaults } from 'axios'
import { IApiClient } from '../Domain/Repositories/Http'

export const queryParamsSerializer = (params: any) =>
  Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')

export function apiInstance(
  api: AxiosInstance,
  {
    onError,
    onSuccess
  }: {
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- Legacy
    onError?: (error: AxiosError<CommutifiErrorData>) => void | typeof CANCELED_API_REQ
    onSuccess?: (res: AxiosResponse) => void
  } = {}
) {
  return async function axiosApi<ApiResponse = any, QueryParam = Record<string, unknown>>(
    url: string,
    options: AxiosRequestConfig & { queryParams?: QueryParam } = {}
  ): Promise<ApiResponse> {
    // Support legacy code where we made queryParams option available
    if (options.queryParams) {
      options.params = options.queryParams
    }

    // Filter out undefined query params
    if (options.params) {
      Object.keys(options.params).forEach((key) => options.params[key] === undefined && delete options.params[key])
    }

    return api(url, options)
      .then((res) => {
        onSuccess?.(res)
        return res.data
      })
      .catch((error: AxiosError<CommutifiErrorData>) => {
        const err = onError?.(error)
        if (err === CANCELED_API_REQ) {
          return err
        }
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // eslint-disable-next-line @typescript-eslint/no-throw-literal -- That is from axios doc, changing it will break error handling
          throw error.response
        }
        if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          throw error.request
        }
        // Something happened in setting up the request that triggered an Error
        throw error
      })
  }
}

export class AxiosHttpClient implements IApiClient {
  private client: AxiosInstance

  public getClient() {
    return this.client
  }

  protected createAxiosClient(config: CreateAxiosDefaults): AxiosInstance {
    return Axios.create({
      responseType: 'json' as const,
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: 10 * 1000,
      ...config
    })
  }

  constructor(config: CreateAxiosDefaults) {
    this.client = this.createAxiosClient(config)
  }

  async post<TBody, TResponse>(path: string, body: TBody, config?: AxiosRequestConfig<TBody>): Promise<TResponse> {
    return apiInstance(this.client)<TResponse>(path, {
      method: 'post',
      data: body,
      ...config
    })
  }

  async patch<TResponse, TBody>(path: string, payload: TBody, config?: AxiosRequestConfig<TBody>): Promise<TResponse> {
    return apiInstance(this.client)<TResponse>(path, {
      method: 'patch',
      data: payload,
      ...config
    })
  }

  async put<TResponse, TBody>(path: string, payload: TBody, config?: AxiosRequestConfig<TBody>): Promise<TResponse> {
    return apiInstance(this.client)<TResponse>(path, {
      method: 'put',
      data: payload,
      ...config
    })
  }

  async get<TResponse>(path: string, config?: AxiosRequestConfig): Promise<TResponse> {
    return apiInstance(this.client)<TResponse>(path, {
      method: 'get',
      ...config
    })
  }
}
