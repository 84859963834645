import { MutationConfig, QueryConfig, QueryConfigPickRes } from 'api/reactQuery'
import {
  DetailedPlanQueryParams,
  PlanBookingQueryParams,
  fetchOnePlan,
  fetchPlanBookings,
  fetchPlanCheckout,
  fetchPlanIndividualBookingItemSelection,
  postPlanGroups
} from './api'
import { useMutation, useQuery } from '@tanstack/react-query'
import { IndividualBookingItem } from './types'

export const makePlanQueryKeys = {
  fetchMany: 'plans',
  fetchOne: (planId: string | undefined, queryParams: DetailedPlanQueryParams = {}) => [
    makePlanQueryKeys.fetchMany,
    planId,
    queryParams
  ],
  fetchPlanBookings: (planId: string | undefined, queryParams: PlanBookingQueryParams = {}) => [
    makePlanQueryKeys.fetchMany,
    planId,
    'bookings',
    queryParams
  ],
  fetchPlanCheckout: (planId: string | undefined, queryParams: Parameters<typeof fetchPlanCheckout>[1]) => [
    makePlanQueryKeys.fetchMany,
    planId,
    'checkout',
    queryParams
  ],
  fetchPlanIndividualBookingItemSelection: (
    planId: string | undefined,
    dateOptions: { startDate?: string; endDate?: string } = {}
  ) => [makePlanQueryKeys.fetchMany, planId, 'individualBookingItemSelection', dateOptions]
}

export const usePlanBookings = (
  planId: string | undefined,
  queryParams: PlanBookingQueryParams,
  options?: QueryConfigPickRes<typeof fetchPlanBookings, 'records'>
) =>
  useQuery({
    queryKey: makePlanQueryKeys.fetchPlanBookings(planId, queryParams),
    queryFn: async () => {
      if (!planId) {
        throw Error('Missing plan id')
      }

      const { records } = await fetchPlanBookings(planId, queryParams)
      return records
    },
    ...options
  })

export const useDetailedPlan = (
  planId: string,
  queryParams: DetailedPlanQueryParams,
  options?: QueryConfig<typeof fetchOnePlan>
) =>
  useQuery({
    queryKey: makePlanQueryKeys.fetchOne(planId, queryParams),
    queryFn: async () =>
      fetchOnePlan(planId, {
        ...queryParams,
        relations: ['planFaqs', 'service', 'service.provider', 'regions', 'planBookingFields', 'merchant'],
        fields: [
          'merchant.id',
          'merchant.legalName',
          'merchant.termsOfService',
          'merchant.termsOfServiceLink',
          'merchant.currency'
        ]
      }),
    ...options
  })

export const useFetchCheckout = (
  planId: string,
  queryParams: Parameters<typeof fetchPlanCheckout>[1],
  options?: QueryConfigPickRes<typeof fetchPlanCheckout, 'records'>
) =>
  useQuery({
    queryKey: makePlanQueryKeys.fetchPlanCheckout(planId, queryParams),
    queryFn: async () => {
      const { records } = await fetchPlanCheckout(planId, queryParams)
      return records
    },
    ...options
  })

export const useCreatePlanGroups = (
  planId: string,
  options?: MutationConfig<typeof postPlanGroups, Parameters<typeof postPlanGroups>[1]>
) =>
  useMutation({
    mutationFn: (body) => postPlanGroups(planId, body),
    ...options
  })

export const usePlanIndividualBookingItemSelection = (
  planId: string | undefined,
  dateOptions?: { startDate?: string; endDate?: string },
  options?: QueryConfig<typeof fetchPlanIndividualBookingItemSelection>
) =>
  useQuery<IndividualBookingItem[]>({
    queryKey: makePlanQueryKeys.fetchPlanIndividualBookingItemSelection(planId, dateOptions),
    queryFn: async () => {
      if (!planId) {
        throw Error('Missing plan id')
      }
      return fetchPlanIndividualBookingItemSelection(planId, dateOptions)
    },
    enabled: !!planId,
    ...options
  })
