import { Required } from 'utility-types'
import { CommuteSectionCategory } from '@commutifi/models/CommuteSections'
import {
  CommuteSectionRepositoryImpl,
  CommuteSectionResourcesRepositoryImpl,
  CommuteSectionsRepositoryImpl
} from '@commutifi-fe/commutifi-specific/Data/Repositories/CommuteSections'
import {
  GetCommuteSectionRequest,
  GetCommuteSectionResourcesRequest,
  GetCommuteSectionsRequest
} from '@commutifi-specific/Domain/Repositories/CommuteSection'
import { CommuteResource, CommuteSection } from '@commutifi-specific/Domain/Models/CommuteSection'
import type { GetResponse, RequestParams } from 'shared/interfaces/Api'
import { apiDashboardServer, AxiosHttpInstance } from 'api/index'
import endpoints from './endpoints'

export type FetchCommuteSectionCategoryParams = {
  enterpriseId?: string
  category?: CommuteSectionCategory
}

export type CommuteSectionCreateParams = Omit<Required<CommuteSection, 'enterpriseId'>, 'id' | 'offices'> & {
  commuteSectionsOffices?: {
    officeId?: string
    commuteSectionId?: string
  }[]
}
export type CommuteSectionUpdateParams = Partial<CommuteSectionCreateParams>

type CommuteSectionQueryParams = RequestParams
/**
 * We need to make sure that either enterpriseId or accountId is provided, but not both.
 * enterpriseId is used to extract enterprise commute sections, and accountId is used to extract account
 * enterprise commute sections WITH RESTRICTIONS on offices associated with the commute sections.
 */
type CommuteSectionsQueryParams = RequestParams &
  ({ enterpriseId: string; accountId?: undefined } | { enterpriseId?: undefined; accountId: string })
type ResourcesQueryParams = RequestParams

export type CommuteSectionRequest = GetCommuteSectionRequest<CommuteSectionQueryParams>
export type CommuteSectionsRequest = GetCommuteSectionsRequest<CommuteSectionsQueryParams>
export const makeCommuteSectionRequest = (
  sectionId: string | undefined,
  query: CommuteSectionRequest['queryParams'] = {},
  options: CommuteSectionRequest['queryOptions'] = {}
) => new GetCommuteSectionRequest<CommuteSectionQueryParams>(sectionId, query, options)

export const makeCommuteSectionsRequest = (
  query: CommuteSectionsRequest['queryParams'],
  options: CommuteSectionsRequest['queryOptions']
) => new GetCommuteSectionsRequest<CommuteSectionsQueryParams>(query, options)

export const makeCommuteSectionRepository = () =>
  CommuteSectionRepositoryImpl<CommuteSectionQueryParams>(
    (sectionId) => endpoints.GET.ById.route(sectionId || ''),
    AxiosHttpInstance
  )
export const makeCommuteSectionsRepository = () =>
  CommuteSectionsRepositoryImpl<CommuteSectionsQueryParams>(endpoints.GET.All.route(), AxiosHttpInstance)

export type CommuteSectionResourcesRequest = GetCommuteSectionResourcesRequest<ResourcesQueryParams>
export const makeCommuteSectionResourcesRequest = (
  sectionId: CommuteSectionResourcesRequest['sectionId'],
  query: CommuteSectionResourcesRequest['queryParams'] = {},
  options?: CommuteSectionResourcesRequest['queryOptions']
) => new GetCommuteSectionResourcesRequest<ResourcesQueryParams>(sectionId, query, options)

export const makeCommuteSectionResourcesRepository = () =>
  CommuteSectionResourcesRepositoryImpl<ResourcesQueryParams>(
    {
      getResources: (sectionId) => endpoints.GET.Resources.route(sectionId || '')
    },
    AxiosHttpInstance
  )

export function getCommuteSections(
  queryParams?: RequestParams &
    Partial<Record<keyof CommuteSection, string | string[]>> &
    ({ enterpriseId: string; accountId?: undefined } | { enterpriseId?: undefined; accountId: string })
): Promise<GetResponse<CommuteSection>> {
  return apiDashboardServer(endpoints.GET.All.route(), {
    method: 'get',
    params: queryParams
  })
}

export function getOneCommuteSection(
  sectionId: string,
  queryParams?: RequestParams & Partial<Record<keyof CommuteSection, string | string[]>>
): Promise<CommuteSection> {
  return apiDashboardServer(endpoints.GET.ById.route(sectionId), {
    method: 'get',
    params: queryParams
  })
}

export function getCommuteSectionResources(
  sectionId: string,
  queryParams?: RequestParams & Partial<Record<keyof CommuteResource, string | string[]>>
): Promise<GetResponse<CommuteResource[]>> {
  return apiDashboardServer(endpoints.GET.Resources.route(sectionId), {
    method: 'get',
    params: queryParams
  })
}

export const createCommuteSection = (data: CommuteSectionCreateParams): Promise<CommuteSection[]> =>
  apiDashboardServer(endpoints.POST.CommuteSections.route(), {
    method: 'post',
    data
  })

export const updateCommuteSection = (
  commuteSectionId: string,
  data: CommuteSectionUpdateParams
): Promise<CommuteSection> =>
  apiDashboardServer(endpoints.PATCH.ById.route(commuteSectionId), {
    method: 'patch',
    data
  })

export const deleteOneCommuteSection = (commuteSectionId: string): Promise<void> =>
  apiDashboardServer(endpoints.DELETE.ById.route(commuteSectionId), {
    method: 'delete'
  })

export const deleteManyCommuteSections = (params: FetchCommuteSectionCategoryParams): Promise<void> =>
  apiDashboardServer(endpoints.DELETE.Many.route(), {
    method: 'delete',
    params
  })
