import { createContext, useMemo, useContext } from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Overwrite } from 'utility-types'
import { makeCommuteSectionsRepository } from 'api/modules/commuteSections'
import { useCommuteSections as useFetchCommuteSections } from '@commutifi-fe/commutifi-specific/Presentation/CommuteSections/hooks'
import { CommuteSectionsRepository } from '@commutifi-fe/commutifi-specific/Domain/Repositories/CommuteSection'
import { CommuteSection } from '@commutifi-fe/commutifi-specific/Domain/Models/CommuteSection'
import { CommutifiErrorData } from '@commutifi-fe/interfaces'
import { CommutifiError } from 'shared/interfaces'
import { useCurrentAccount } from './CurrentAccount'

interface CommuteSectionsProviderProps {
  /**
   * Enable data fetching in the provider. This allows us to use
   * the provider at top level tree without being concerned to fetch
   * too many times the data when we don't need it
   */
  enabled?: boolean
  children: React.ReactNode
}

type ICommuteSectionsContext = Overwrite<
  Partial<UseQueryResult<CommuteSection[], CommutifiError>>,
  { isError: boolean }
> & {
  commuteSections?: CommuteSection[]
  error: AxiosResponse<CommutifiErrorData, any> | null
}

export const CommuteSectionsContext = createContext<ICommuteSectionsContext | null>(null)

const commuteSectionsRepository = makeCommuteSectionsRepository()
function CommuteSectionsProvider({ enabled, children }: CommuteSectionsProviderProps) {
  const { currentAccount } = useCurrentAccount()
  const request = useMemo<Parameters<typeof commuteSectionsRepository>[0]>(
    () => ({
      queryParams: {
        accountId: currentAccount.id
      },
      queryOptions: { enabled }
    }),
    [enabled, currentAccount.id]
  )

  const fetchCommuteSectionsUseCase = useFetchCommuteSections(commuteSectionsRepository(request))

  const {
    isLoading,
    isFetching,
    isError,
    isFetched,
    error,
    data: commuteSections
  } = fetchCommuteSectionsUseCase.execute()

  const value = useMemo(
    () => ({
      isLoading,
      isFetching,
      isFetched,
      isError,
      error,
      commuteSections
    }),
    [isLoading, isFetching, isFetched, isError, error, commuteSections]
  )
  if (!enabled) {
    return children
  }
  return <CommuteSectionsContext.Provider value={value}>{children}</CommuteSectionsContext.Provider>
}

const useCommuteSectionsContext = () => {
  const context = useContext(CommuteSectionsContext)
  if (!context) {
    throw new Error('useCommuteSectionsContext must be used within a CommuteSectionsProvider')
  }
  return context
}

export { CommuteSectionsProvider, useCommuteSectionsContext }

export function makeCommuteSectionsProviderRepository<
  TQueryParams extends Record<string, any>
>(): CommuteSectionsRepository<TQueryParams> {
  return {
    ...makeCommuteSectionsRepository(),
    useCommuteSections: () => {
      const { isLoading, error, isFetched, commuteSections } = useCommuteSectionsContext()
      return {
        isLoading: Boolean(isLoading),
        error,
        isError: Boolean(error),
        isFetching: Boolean(isLoading && !commuteSections),
        isFetched: Boolean(isFetched),
        data: commuteSections
      }
    }
  }
}
