import { merge } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import { GetResponse } from '@commutifi-fe/interfaces'
import { IApiClient } from '../../Domain/Repositories/Http'
import {
  CommuteSectionRepository,
  CommuteSectionResourcesRepository,
  CommuteSectionsRepository,
  GetCommuteSectionRequest,
  GetCommuteSectionResourcesRequest,
  GetCommuteSectionsRequest
} from '../../Domain/Repositories/CommuteSection'
import { CommuteSection, CommuteResource } from '../../Domain/Models/CommuteSection'

export function CommuteSectionRepositoryImpl<TQueryParams extends Record<string, any>>(
  getUrl: (sectionId: string | undefined) => string,
  httpClient: IApiClient
) {
  return (request?: GetCommuteSectionRequest<TQueryParams>): CommuteSectionRepository<TQueryParams> => ({
    useCommuteSection: (req) => {
      const { sectionId, queryParams, queryOptions } = merge({}, req, request)
      return useQuery({
        queryKey: ['commute-sections', sectionId, queryParams],
        queryFn: () =>
          httpClient.get<GetResponse<CommuteSection>>(getUrl(sectionId), {
            params: queryParams
          }),
        enabled: !!sectionId,
        ...queryOptions
      })
    }
  })
}

export function CommuteSectionsRepositoryImpl<TQueryParams extends Record<string, any>>(
  url: string,
  httpClient: IApiClient
) {
  return (request?: GetCommuteSectionsRequest<TQueryParams>): CommuteSectionsRepository<TQueryParams> => ({
    useCommuteSections: (req) => {
      const { queryParams, queryOptions } = merge({}, req, request)
      const params = merge({}, queryParams, { sort: '+order' })
      return useQuery({
        queryKey: ['commute-sections', params],
        queryFn: () => httpClient.get<GetResponse<CommuteSection>>(url, { params }),
        select: (data) => data?.records,
        ...queryOptions
      })
    }
  })
}

export function CommuteSectionResourcesRepositoryImpl<TQueryParams extends Record<string, any>>(
  uris: { getResources: (sectionId: string | undefined) => string },
  httpClient: IApiClient
) {
  return (
    request?: GetCommuteSectionResourcesRequest<TQueryParams>
  ): CommuteSectionResourcesRepository<TQueryParams> => ({
    useCommuteSectionResources: (req) => {
      const { sectionId, queryParams, queryOptions } = merge({}, req, request)
      return useQuery({
        queryKey: [`commute-sections/${sectionId}/resources`, queryParams],
        queryFn: () =>
          httpClient.get<GetResponse<CommuteResource>>(uris.getResources(sectionId), { params: queryParams }),
        select: (data) => data?.records,
        enabled: !!sectionId,
        ...queryOptions
      })
    }
  })
}
