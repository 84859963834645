import { GetResponse } from '@commutifi-fe/interfaces'
import { UseQueryOptions } from '@tanstack/react-query'
import { CommuteSection } from '../../../Models/CommuteSection'

export class GetCommuteSectionsRequest<TQueryParams extends Record<string, any>> {
  constructor(
    public readonly queryParams?: TQueryParams,
    public readonly queryOptions?: Partial<
      UseQueryOptions<GetResponse<CommuteSection>, any, CommuteSection[], [string, TQueryParams | undefined]>
    >
  ) {}
}
