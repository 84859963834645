import type { IntlFormatters, IntlShape, MessageDescriptor } from 'react-intl'
import { createIntl, createIntlCache } from 'react-intl'
import en from './en'
import { isSupportedLocaleType, Locales, LocalesType } from '@commutifi-fe/constants'
import { ConfigContext } from '@commutifi-fe/ui'
import { useContext, useState, useEffect, useMemo } from 'react'
import { Overwrite, DeepRequired } from 'utility-types'
import { usePrevious } from '@commutifi-fe/hooks'

export type {
  CustomFormatConfig,
  CustomFormats,
  FormatDateOptions,
  FormatDisplayNameOptions,
  FormatListOptions,
  FormatNumberOptions,
  FormatPluralOptions,
  FormatRelativeTimeOptions,
  Formatters,
  IntlCache,
  IntlConfig,
  IntlFormatters,
  IntlShape,
  MessageDescriptor,
  MessageFormatElement,
  ResolvedIntlConfig,
  WithIntlProps,
  WrappedComponentProps
} from 'react-intl'

export { en as defaultMessages }

export const DEFAULT_LOCALE = Locales.enUS
export enum LocalesFilename {
  Cs = 'cs',
  DeDe = 'de-DE',
  En = 'en',
  EsEs = 'es-ES',
  Fr = 'fr',
  ItIt = 'it-IT',
  NlNl = 'nl-NL',
  Pl = 'pl',
  PtPt = 'pt-PT'
}

export const messages = {
  [LocalesFilename.En]: () =>
    new Promise<{ default: typeof en }>((resolve) => {
      resolve({ default: en })
    }),
  [LocalesFilename.Cs]: () => import('./cs'),
  [LocalesFilename.DeDe]: () => import('./de-DE'),
  [LocalesFilename.EsEs]: () => import('./es-ES'),
  [LocalesFilename.Fr]: () => import('./fr'),
  [LocalesFilename.ItIt]: () => import('./it-IT'),
  [LocalesFilename.NlNl]: () => import('./nl-NL'),
  [LocalesFilename.Pl]: () => import('./pl'),
  [LocalesFilename.PtPt]: () => import('./pt-PT')
}

type IntlMessage = typeof en
export type IntlMessageComponents = keyof IntlMessage
export type IntlMessageKeys<C extends IntlMessageComponents> = keyof IntlMessage[C]

// The arguments to the original formatMessage function.
type FormatMessageArgs = Parameters<IntlFormatters['formatMessage']>

export type RecursiveKeyOf<TObj> = {
  [TKey in keyof TObj & (string | number)]: TObj[TKey] extends any[]
    ? `${TKey}`
    : TObj[TKey] extends object
      ? `${TKey}` | `${TKey}.${RecursiveKeyOf<TObj[TKey]>}`
      : `${TKey}`
}[keyof TObj & (string | number)]

// Define PrimitiveType and FormatXMLElementFn which are used by react-intl but not directly exportable
type PrimitiveType = string | number | boolean | null | undefined
type FormatXMLElementFn<T, R> = (parts: T[]) => R

// Modify TypedIntl to be more compatible with IntlShape
export interface TypedIntl<C extends IntlMessageComponents> extends Omit<IntlShape, 'formatMessage'> {
  formatMessage(
    descriptor: MessageDescriptor & {
      id: RecursiveKeyOf<DeepRequired<IntlMessage>[C] & IntlMessage['global']>
    },
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    options?: FormatMessageArgs[2]
  ): string
}

export const useLocaleCodeFromConfig = () => {
  const { localeKey } = useContext(ConfigContext)
  return isSupportedLocaleType(localeKey) ? localeKey : Locales.enUS
}

export const localeToSupportedTranslationFile = (locale: LocalesType): keyof typeof messages => {
  switch (locale) {
    case Locales.enCA:
    case Locales.enGB:
    case Locales.enUS:
      return LocalesFilename.En
    case Locales.cs:
      return LocalesFilename.Cs
    case Locales.deDE:
      return LocalesFilename.DeDe
    case Locales.esES:
      return LocalesFilename.EsEs
    case Locales.frBE:
    case Locales.frCA:
      return LocalesFilename.Fr
    case Locales.itIT:
      return LocalesFilename.ItIt
    case Locales.nlBE:
    case Locales.nlNL:
      return LocalesFilename.NlNl
    case Locales.pl:
      return LocalesFilename.Pl
    case Locales.ptPT:
      return LocalesFilename.PtPt
    default:
      return LocalesFilename.En
  }
}

const intlCache = createIntlCache()
export const useComponentIntl = <C extends IntlMessageComponents = IntlMessageComponents>(componentName: C) => {
  const componentNames: IntlMessageComponents[] = useMemo(() => [componentName, 'global'], [componentName])
  const locale = useLocaleCodeFromConfig()
  const previousLocale = usePrevious(locale)

  const englishMessages = useMemo(
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter -- Ok here
    () => componentNames.reduce<IntlMessage[C]>((acc, compName) => ({ ...acc, ...en[compName] }), {} as IntlMessage[C]),
    [componentNames]
  )
  const [lazyLoadedMessages, setLazyLoadedMessages] = useState<IntlMessage[C]>(englishMessages)
  useEffect(() => {
    const intlTranslationsName = localeToSupportedTranslationFile(locale)
    if ((!previousLocale && locale === Locales.enUS) || locale === previousLocale) {
      return
    }

    messages[intlTranslationsName]()
      .then((module) => {
        // It's fine here it should be partial keys which works with intl
        setLazyLoadedMessages(
          // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter -- Ok here
          componentNames.reduce((acc, compName) => ({ ...acc, ...module.default[compName] }), {} as IntlMessage[C])
        )
      })
      .catch(() => {
        // Nothing for now
      })
  }, [componentNames, locale, previousLocale])

  const messagesWithEnglishAsFallback = useMemo(
    () => (locale === DEFAULT_LOCALE ? englishMessages : { ...englishMessages, ...lazyLoadedMessages }),
    [englishMessages, lazyLoadedMessages, locale]
  )
  const intlData = useMemo(() => {
    return {
      locale,
      messages: messagesWithEnglishAsFallback
    }
  }, [locale, messagesWithEnglishAsFallback])

  const intl: TypedIntl<C> = useMemo(() => createIntl(intlData, intlCache), [intlData])
  return intl
}
