import { merge } from 'lodash'
import { useMutation, useQuery, ExtractFnReturnType, MutationConfig, QueryConfig } from 'api/reactQuery'
import {
  getCommuteSections,
  createCommuteSection,
  updateCommuteSection,
  CommuteSectionCreateParams,
  CommuteSectionUpdateParams,
  getOneCommuteSection,
  getCommuteSectionResources,
  deleteManyCommuteSections,
  deleteOneCommuteSection
} from './api'

const makeCommuteSectionsQueryKeys = {
  all: (queryParams: Parameters<typeof getCommuteSections>[0]) => ['commute-sections', queryParams] as const,
  one: (sectionId: string, queryParams: Parameters<typeof getOneCommuteSection>[1]) =>
    ['commute-sections', sectionId, queryParams] as const,
  resources: (sectionId: string, queryParams: Parameters<typeof getCommuteSectionResources>[1]) =>
    ['commute-sections', sectionId, 'resources', queryParams] as const
}

export function useCommuteSections<TData = ExtractFnReturnType<typeof getCommuteSections>>(
  queryParams: Parameters<typeof getCommuteSections>[0],
  options?: QueryConfig<typeof getCommuteSections, TData>
) {
  const paramsWithDefaultSort = merge({}, { sort: '+order' }, queryParams)
  return useQuery({
    queryKey: makeCommuteSectionsQueryKeys.all(paramsWithDefaultSort),
    queryFn: async () => getCommuteSections(paramsWithDefaultSort),
    ...options
  })
}

export function useOneCommuteSection<TData = ExtractFnReturnType<typeof getOneCommuteSection>>(
  sectionId: string,
  queryParams: Parameters<typeof getOneCommuteSection>[1],
  options?: QueryConfig<typeof getOneCommuteSection, TData>
) {
  return useQuery({
    queryKey: makeCommuteSectionsQueryKeys.one(sectionId, queryParams),
    queryFn: async () => getOneCommuteSection(sectionId, queryParams),
    ...options
  })
}

export function useCommuteSectionResources<TData = ExtractFnReturnType<typeof getCommuteSectionResources>>(
  sectionId: string,
  queryParams: Parameters<typeof getCommuteSectionResources>[1],
  options?: QueryConfig<typeof getCommuteSectionResources, TData>
) {
  return useQuery({
    queryKey: makeCommuteSectionsQueryKeys.resources(sectionId, queryParams),
    queryFn: async () => getCommuteSectionResources(sectionId, queryParams),
    ...options
  })
}

export const useCreateCommuteSection = (options?: MutationConfig<typeof createCommuteSection>) =>
  useMutation({
    mutationFn: (data: CommuteSectionCreateParams) => createCommuteSection(data),
    ...options
  })

export type UseUpdateCommuteSectionData = {
  commuteSectionId: string
  data: CommuteSectionUpdateParams
}
export const useUpdateCommuteSection = (
  options?: MutationConfig<typeof updateCommuteSection, UseUpdateCommuteSectionData>
) =>
  useMutation({
    mutationFn: ({ commuteSectionId, data }) => updateCommuteSection(commuteSectionId, data),
    ...options
  })

export const useDeleteManyCommuteSections = (options?: MutationConfig<typeof deleteManyCommuteSections>) =>
  useMutation({
    mutationFn: (params) => deleteManyCommuteSections(params),
    ...options
  })

export const useDeleteOneCommuteSection = (options?: MutationConfig<typeof deleteOneCommuteSection>) =>
  useMutation({
    mutationFn: (commuteSectionId) => deleteOneCommuteSection(commuteSectionId),
    ...options
  })
