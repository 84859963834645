import { GetResponse } from '@commutifi-fe/interfaces'
import { UseQueryOptions } from '@tanstack/react-query'
import { CommuteResource } from '../../../Models/CommuteSection'

export class GetCommuteSectionResourcesRequest<TQueryParams extends Record<string, any>> {
  constructor(
    public readonly sectionId: string | undefined,
    public readonly queryParams?: TQueryParams,
    public readonly queryOptions?: Partial<
      UseQueryOptions<GetResponse<CommuteResource>, any, CommuteResource[], [string, TQueryParams | undefined]>
    >
  ) {}
}
