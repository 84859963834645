import { HttpMethod } from 'api/constants'

function returnPath(): string {
  return this.path
}

function replaceSectionId(sectionId: string): string {
  return this.path.replace(':commuteSectionId', sectionId)
}

export const commuteSectionsEndpoints = {
  [HttpMethod.Get]: {
    All: {
      path: '/commute-sections',
      route: returnPath
    },
    ById: {
      path: '/commute-sections/:commuteSectionId',
      route: replaceSectionId
    },
    Resources: {
      path: '/commute-sections/:commuteSectionId/resources',
      route: replaceSectionId
    }
  },
  [HttpMethod.Post]: {
    CommuteSections: {
      path: '/commute-sections',
      route: returnPath
    }
  },
  [HttpMethod.Patch]: {
    ById: {
      path: '/commute-sections/:commuteSectionId',
      route(commuteSectionId: string): string {
        return this.path.replace(':commuteSectionId', commuteSectionId)
      }
    }
  },
  [HttpMethod.Delete]: {
    ById: {
      path: '/commute-sections/:commuteSectionId',
      route: replaceSectionId
    },
    Many: {
      path: '/commute-sections',
      route: returnPath
    }
  }
}

export default commuteSectionsEndpoints
