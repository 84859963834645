import AiSearch from './translations/aiSearch.en.json'
import CommuteSections from './translations/commuteSections.en.json'
import errors from './translations/errors.en.json'
import FrequencyDisplay from './translations/frequencyDisplay.en.json'
import global from './translations/global.en.json'
import Metrics from './translations/metrics.en.json'
import phone from './translations/phone.en.json'
import PlanCard from './translations/planCard.en.json'

export default {
  AiSearch,
  CommuteSections,
  errors,
  FrequencyDisplay,
  global,
  Metrics,
  phone,
  PlanCard
}
