import { uuidPattern } from 'utils/helpers'
import { logger } from 'utils/logRocket'

/*
 * Available pages enums
 */
export enum PublicPages {
  Auth = 'auth',
  ResetPassword = 'reset-password',
  CreateAccount = 'create'
}

export enum EnterpriseSurveyPages {
  Auth = 'auth',
  PublicWelcome = 'welcome',
  Onboarding = 'onboarding',
  Register = 'register',
  Success = 'success'
}

export enum EnterpriseRegistrationPages {
  Success = 'success'
}

export enum AdminDashboardPages {
  Analytics = 'analytics',
  Announcements = 'announcements',
  Billing = 'billing',
  Compliance = 'compliance',
  Employees = 'employees',
  Enrollments = 'enrollments',
  Enterprises = 'children',
  Groups = 'groups',
  CommuteSections = 'guides',
  Integrations = 'integrations',
  Managers = 'managers',
  Offices = 'offices',
  Plans = 'plans',
  Wallets = 'wallets',
  Settings = 'settings',
  Submissions = 'submissions',
  Subscriptions = 'subscriptions',
  Surveys = 'surveys'
}

export enum AnalyticsSubPages {
  Commute = 'commute',
  Modeling = 'modeling',
  Report = 'report',
  Surveys = 'surveys',
  Datasets = 'datasets'
}

export const AdminDashboardSubPages = AnalyticsSubPages
export const AdminSubPagesByPage = { [AdminDashboardPages.Analytics]: AnalyticsSubPages }

export enum CommuteAnalyticsTabViews {
  Map = 'map',
  SummaryAndCommuting = 'summary',
  Schedule = 'schedule',
  Sustainability = 'sustainability',
  Vehicle = 'vehicle'
}
export enum ModelingAnalyticsPages {
  ActiveCommute = 'activeCommute',
  Transit = 'transit'
}
export enum ModelingAnalyticsPagesComingSoon {
  Vanpool = 'vanpool',
  Shuttle = 'shuttle'
}

export enum ActionPages {
  Create = 'create'
}

export enum AdminBillingPages {
  AddPayment = 'add-payment-method',
  Balance = 'balance',
  TopUps = 'top-ups',
  Payment = 'payment',
  Invoices = 'invoices'
}

export enum AdminEmployeePages {
  Groups = 'groups',
  Employees = 'employees'
}

export enum AdminGroupPages {
  Members = 'members'
}

export enum AdminPlansPages {
  Plans = 'plans',
  Subscriptions = 'subscriptions'
}

export enum AdminSettingsPages {
  Integrations = 'integrations',
  Managers = 'managers'
}

export enum AdminCompliancePages {
  DDOT = 'ddot'
}

export enum AdminComplianceSubPages {
  Create = 'create',
  TdmPlanSummary = 'tdm-plan-summary'
}

export enum AdminWalletPages {
  Enrollments = 'enrollments',
  Wallets = 'wallets'
}

export enum AdminAnnouncementPages {
  Announcements = 'announcements'
}

export enum AdminWalletCreationPages {
  Plans = 'plans',
  Card = 'card',
  SpendingCategories = 'categories',
  Allocation = 'allocation',
  Groups = 'groups',
  Details = 'details',
  Terms = 'terms'
}

export enum AdminComplianceCreationPages {
  Profile = 'profile',
  Options = 'options',
  Answers = 'answers'
}

export enum AdminEnterpriseCreationPages {
  Profile = 'profile',
  Plans = 'plans',
  Invitation = 'invitation'
}

export enum AdminSurveyCreationPages {
  Design = 'design',
  Content = 'content',
  Share = 'share'
}

export enum CommuterDashboardPages {
  Dashboard = 'dashboard',
  CommuteSections = 'commute',
  Routes = 'routes',
  Benefits = 'benefits',
  Plans = 'plans',
  Bookings = 'bookings',
  Trips = 'trips',
  Settings = 'settings',
  Card = 'card',
  Wallets = 'wallets',
  Incentive = 'incentive',
  AiSearch = 'search',
  // !Available only in the header menu (or mobile menu)
  Connect = 'connect'
}

export enum CheckoutPages {
  Checkout = 'checkout',
  Success = 'success',
  Payment = 'payment'
}

export enum CommuterDashboardPathsParams {
  SettingsPage = 'settingsPage',
  CommuteProfileId = 'commuteProfileId',
  IncentiveProgramId = 'incentiveProgramId',
  BookingPage = 'bookingPage',
  BookingId = 'bookingId',
  PlanId = 'planId',
  BenefitId = 'benefitId',
  WalletId = 'walletId',
  TripPage = 'tripsPage',
  CommuteSectionCategory = 'commuteSectionCategory',
  RouteId = 'routeId'
}

export enum SettingsPages {
  Account = 'account',
  Commute = 'commute',
  CreateCommute = 'create',
  ConnectedApps = 'connect'
}

export enum BookingDetailsPages {
  Overview = 'overview',
  Billing = 'billing',
  Registration = 'registration',
  Cancel = 'cancel'
}

/*
 * React-router paths parameter enums
 */
export enum PublicPathsParams {
  AccountId = 'accountId',
  AdminId = 'adminId',
  Shortlink = 'shortlink',
  RegistrationId = 'enterpriseRegistrationId'
}

export enum PrivatePathsParams {
  InvoiceId = 'invoiceId'
}

export enum AdminPathsParams {
  MenuPage = 'page',
  SubMenuPage = 'subPage',
  EmployeeOrGroupId = 'employeeOrGroupId',
  EnterpriseId = 'enterpriseId',
  GroupId = 'groupId',
  SubscriptionOrPlanId = 'subscriptionOrPlanId',
  EnrolmentOrWalletId = 'enrolmentOrWalletId',
  AnnouncementId = 'announcementId',
  SubmissionId = 'submissionId',
  CreationStep = 'step',
  SurveyId = 'surveyId',
  CommuteSectionCategory = 'commuteSectionCategory'
}

export enum AdminDashboardParams {
  AnalyticReportId = 'analyticReportId',
  SurveyId = 'surveyId'
}

export enum AdminBillingPathsParams {
  Page = 'billingPage'
}

export enum AdminSubPagePathsParams {
  EmployeePage = 'employeesPage',
  GroupPage = 'groupsPage',
  EnterprisePage = 'enterprisePage',
  WalletPage = 'walletPage',
  AnnouncementPage = 'announcementPage',
  CompliancePage = 'compliancePage',
  PlanPage = 'planPage',
  AnalyticsPage = 'analyticsPage',
  CommuteAnalyticsPage = 'commuteAnalyticsPage',
  SurveyAnalyticsPage = 'surveyAnalyticsPage',
  SettingsPage = 'settingsPage',
  SurveyCreationPage = 'surveyCreationPage'
}

export enum CommuterPathsParams {
  Page = 'page',
  SuperAdminId = 'superAdminId'
}

export enum EnterpriseSurveyPathsParams {
  Step = 'step'
}

const PARAM_SEPARATOR = '|'
const PARAM_PREFIX = ':'
const OPTIONAL_PARAM_SUFFIX = '?'
const createPathParam = (
  subPage: string,
  {
    isOptional,
    subPageValues,
    pattern
  }:
    | { subPageValues?: string[]; isOptional?: boolean; pattern?: undefined }
    | { subPageValues?: undefined; isOptional?: boolean; pattern: string } = {}
) =>
  `${PARAM_PREFIX}${subPage}${pattern ? `(${pattern})` : ''}${subPageValues ? `(${subPageValues.join(PARAM_SEPARATOR)})` : ''}${isOptional ? OPTIONAL_PARAM_SUFFIX : ''}`

const replaceAdminId = (path: string, adminId: string) => path.replace(`:${PublicPathsParams.AdminId}`, adminId)

/**
 * Application Nested rooting objects:
 * PublicRouting, CommonRouting, CommuterRouting, AdminRouting, etc.
 *
 * Two key concepts to understand here:
 *  1. Path: A representation of multiple routes a user can go
 *     through URLs. Usually contains parameters like /plans/:planId
 *  2. Route: Simply a path with all parameters filled (/plans/1)
 *
 * So path is like a template of what the url looks like
 * and then the route is what the url actually looks like
 *
 * In other words, a path is simply a pattern potentially matching multiple routes.
 * @example Take this path: `plans/:planId`. It has an infinity of routes it matches:
 *          `plans/1`, `plans/`2, `plans/3`, ...
 */
export const PublicRouting = {
  root: {
    path: '/',
    getRoute(): string {
      return this.path
    }
  },
  auth: {
    path: '/auth',
    getRoute(): string {
      return this.path
    }
  },
  oAuth: {
    path: '',
    getRoute(): string {
      return this.path
    },
    sso: {
      path: '/sso/callback',
      getRoute(): string {
        return this.path
      }
    },
    mobilityService: {
      path: '/mobility-services/callback',
      getRoute(): string {
        return this.path
      }
    },
    arc: {
      path: '/arc',
      getRoute(): string {
        return this.path
      }
    },
    stripe: {
      path: '/stripe',
      getRoute(): string {
        return this.path
      },
      customConnect: {
        path: '/stripe/custom',
        getRoute(): string {
          return this.path
        }
      }
    }
  },
  emailVerification: {
    path: '/email-verification',
    getRoute(): string {
      return this.path
    }
  },
  passwordCreation: {
    path: `/accounts/:${PublicPathsParams.AccountId}(${uuidPattern})`,
    getRoute(id: string): string {
      return this.path.replace(`:${PublicPathsParams.AccountId}`, id)
    }
  },
  enterpriseSurvey: {
    path: `/to/:${PublicPathsParams.Shortlink}`,
    get optional() {
      return { path: `${this.path}?` }
    },
    getRoute(id: string): string {
      return this.path.replace(`:${PublicPathsParams.Shortlink}`, id)
    },
    get auth() {
      return { path: `${this.path}/${EnterpriseSurveyPages.Auth}`, getRoute: this.getRoute }
    },
    get publicWelcome() {
      return { path: `${this.path}/${EnterpriseSurveyPages.PublicWelcome}`, getRoute: this.getRoute }
    },

    get onboarding() {
      return {
        path: `${this.path}/onboarding`,
        getRoute: this.getRoute,
        get step() {
          return {
            path: `${this.path}/:${EnterpriseSurveyPathsParams.Step}(step[1-9][0-9]{0,1})`,
            getRoute(shortlink: string, stepNb: string) {
              return this.path
                .replace(`:${PublicPathsParams.Shortlink}`, shortlink)
                .replace(`:${EnterpriseSurveyPathsParams.Step}`, stepNb)
            }
          }
        },

        get register() {
          return {
            path: `${this.path}/${EnterpriseSurveyPages.Register}`,
            getRoute(): string {
              return this.path
            }
          }
        },
        get success() {
          return {
            path: `${this.path}/${EnterpriseSurveyPages.Success}`,
            getRoute(): string {
              return this.path
            }
          }
        }
      }
    }
  },
  enterpriseRegistration: {
    path: `/r/:${PublicPathsParams.RegistrationId}`,
    getRoute(id: string): string {
      return this.path.replace(`:${PublicPathsParams.RegistrationId}`, id)
    }
  },
  complianceCalculator: {
    path: '/compliance/calculator',
    getRoute(): string {
      return this.path
    }
  }
}

export const CommonRouting = {
  select: {
    path: '/select',
    getRoute(): string {
      return this.path
    }
  },
  invoice: {
    path: '/invoices',
    getRoute(): string {
      return this.path
    },
    get detail() {
      return {
        path: `${this.path}/:${PrivatePathsParams.InvoiceId}`,
        getRoute(id: string): string {
          return this.path.replace(`:${PrivatePathsParams.InvoiceId}`, id)
        }
      }
    }
  }
}

const getCheckoutSubset = <T extends { path: string; getRoute: (...args: string[]) => string }>(thisObj: T) => ({
  path: `${thisObj.path}/${CheckoutPages.Checkout}`,
  getRoute: thisObj.getRoute,
  get payment() {
    return { path: `${this.path}/${CheckoutPages.Payment}`, getRoute: this.getRoute }
  },
  get success() {
    return { path: `${this.path}/${CheckoutPages.Success}`, getRoute: this.getRoute }
  }
})

export const CommuterRouting = {
  basePage: {
    path: `/:${CommuterPathsParams.Page}`,
    getRoute(page: `${CommuterDashboardPages}`): string {
      return this.path.replace(`:${CommuterPathsParams.Page}`, page)
    },
    superAdmin: {
      path: `/accounts/:${CommuterPathsParams.SuperAdminId}(${uuidPattern})/:${CommuterPathsParams.Page}`,
      getRoute(accountId: string, page: `${CommuterDashboardPages}`): string {
        return this.path
          .replace(CommuterPathsParams.SuperAdminId, accountId)
          .replace(`:${CommuterPathsParams.Page}`, page)
      }
    }
  },
  dashboard: {
    path: `/${CommuterDashboardPages.Dashboard}`,
    getRoute(): string {
      return this.path
    }
  },
  commuteSections: {
    path: `/${CommuterDashboardPages.CommuteSections}/:${CommuterDashboardPathsParams.CommuteSectionCategory}?`,
    getRoute(category?: string): string {
      return this.path.replace(
        `/:${CommuterDashboardPathsParams.CommuteSectionCategory}?`,
        category ? `/${category}` : ''
      )
    }
  },
  routes: {
    path: `/${CommuterDashboardPages.Routes}`,
    getRoute(): string {
      return this.path
    },
    get detail() {
      return {
        path: `${this.path}/:${CommuterDashboardPathsParams.RouteId}`,
        getRoute(id: string): string {
          return this.path.replace(`:${CommuterDashboardPathsParams.RouteId}`, id)
        }
      }
    }
  },
  plans: {
    path: `/${CommuterDashboardPages.Plans}`,
    getRoute(): string {
      return this.path
    },
    get detail() {
      return {
        path: `${this.path}/:${CommuterDashboardPathsParams.PlanId}`,
        getRoute(id: string): string {
          return this.path.replace(`:${CommuterDashboardPathsParams.PlanId}`, id)
        },
        get checkout() {
          return getCheckoutSubset(this)
        },
        get terms() {
          return {
            path: `${this.path}/terms`,
            getRoute: this.getRoute
          }
        }
      }
    }
  },
  benefits: {
    path: `/${CommuterDashboardPages.Benefits}`,
    getRoute(): string {
      return this.path
    },
    get detail() {
      return {
        path: `${this.path}/:${CommuterDashboardPathsParams.BenefitId}`,
        getRoute(id: string): string {
          return this.path.replace(`:${CommuterDashboardPathsParams.BenefitId}`, id)
        },
        get checkout() {
          return getCheckoutSubset(this)
        }
      }
    }
  },
  bookings: {
    path: `/${CommuterDashboardPages.Bookings}`,
    getRoute(): string {
      return this.path
    },
    get detail() {
      const bookingIdParam = createPathParam(CommuterDashboardPathsParams.BookingId)
      return {
        path: `${this.path}/${bookingIdParam}`,
        getRoute(id: string): string {
          return this.path.replace(bookingIdParam, id)
        },
        get optional() {
          return {
            path: `${this.path}?`,
            getRoute(): string {
              return this.path
            }
          }
        },
        get cancel() {
          return { path: `${this.path}/${BookingDetailsPages.Cancel}`, getRoute: this.getRoute }
        },
        get overview() {
          return { path: `${this.path}/${BookingDetailsPages.Overview}`, getRoute: this.getRoute }
        },
        get billing() {
          return { path: `${this.path}/${BookingDetailsPages.Billing}`, getRoute: this.getRoute }
        },
        get registration() {
          return { path: `${this.path}/${BookingDetailsPages.Registration}`, getRoute: this.getRoute }
        }
      }
    }
  },
  trips: {
    path: `/${CommuterDashboardPages.Trips}`,
    getRoute(): string {
      return this.path
    },
    get pages() {
      const tripPageParam = createPathParam(CommuterDashboardPathsParams.TripPage)
      return {
        path: `${this.path}/${tripPageParam}`,
        get optional() {
          return {
            path: `${this.path}?`,
            getRoute(): string {
              return this.path
            }
          }
        },
        getRoute(page: string): string {
          return this.path.replace(tripPageParam, page)
        }
      }
    }
  },
  wallets: {
    path: `/${CommuterDashboardPages.Wallets}`,
    getRoute(): string {
      return this.path
    }
  },
  search: {
    path: `/${CommuterDashboardPages.AiSearch}`,
    getRoute(): string {
      return this.path
    }
  },
  card: {
    path: `/${CommuterDashboardPages.Card}`,
    getRoute(): string {
      return this.path
    }
  },
  settings: {
    path: `/${CommuterDashboardPages.Settings}`,
    getRoute(): string {
      return this.path
    },
    get pages() {
      const settingsPageParam = createPathParam(CommuterDashboardPathsParams.SettingsPage)
      return {
        path: `${this.path}/${settingsPageParam}`,
        get optional() {
          return {
            path: `${this.path}?`,
            getRoute(): string {
              return this.path
            }
          }
        },
        getRoute(page: string): string {
          return this.path.replace(settingsPageParam, page)
        }
      }
    },
    get connect() {
      return {
        path: `${this.path}/${CommuterDashboardPages.Connect}`,
        getRoute(): string {
          return this.path
        }
      }
    },
    get account() {
      return {
        path: `${this.path}/${SettingsPages.Account}`,
        getRoute(): string {
          return this.path
        }
      }
    },
    get commute() {
      return {
        path: `${this.path}/${SettingsPages.Commute}`,
        getRoute(): string {
          return this.path
        },
        get create() {
          return {
            path: `${this.path}/${SettingsPages.CreateCommute}`,
            getRoute(): string {
              return this.path
            }
          }
        },
        get detail() {
          const commuteProfileIdParam = createPathParam(CommuterDashboardPathsParams.CommuteProfileId)
          return {
            path: `${this.path}/${commuteProfileIdParam}`,
            get optional() {
              return {
                path: `${this.path}?`,
                getRoute(): string {
                  return this.path
                }
              }
            },
            getRoute(id: string): string {
              return this.path.replace(commuteProfileIdParam, id)
            }
          }
        }
      }
    }
  },
  get incentive() {
    const incentiveProgramIdParam = createPathParam(CommuterDashboardPathsParams.IncentiveProgramId)
    return {
      path: `/${CommuterDashboardPages.Incentive}/${incentiveProgramIdParam}`,
      getRoute(id: string): string {
        return this.path.replace(incentiveProgramIdParam, id)
      }
    }
  }
}

export const AdminRouting = {
  root: {
    path: `/admins/:${PublicPathsParams.AdminId}`,
    getRoute(id: string): string {
      return replaceAdminId(this.path, id)
    },

    get compliance() {
      const subPages = `(${Object.values(AdminComplianceSubPages).join('|')})`

      return {
        path: `${this.path}/${AdminDashboardPages.Compliance}/:${AdminSubPagePathsParams.CompliancePage}${subPages}`,
        getRoute(adminId: string, subPage?: AdminComplianceSubPages) {
          return this.path
            .replace(`:${PublicPathsParams.AdminId}`, adminId)
            .replace(`:${AdminSubPagePathsParams.CompliancePage}${subPages}`, subPage || '')
        },
        get creation() {
          return {
            path: `${this.path.replace(
              `:${AdminSubPagePathsParams.CompliancePage}${subPages}`,
              `create/:${AdminPathsParams.CreationStep}(${Object.values(AdminComplianceCreationPages).join('|')})?`
            )}`,
            getRoute(adminId: string) {
              return this.path
                .replace(`:${PublicPathsParams.AdminId}`, adminId)
                .replace(
                  `/:${AdminPathsParams.CreationStep}(${Object.values(AdminComplianceCreationPages).join('|')})?`,
                  ''
                )
            }
          }
        }
      }
    },

    get analytics() {
      const analyticsPageOptionalParam = createPathParam(AdminSubPagePathsParams.AnalyticsPage, {
        subPageValues: Object.values(AnalyticsSubPages),
        isOptional: true
      })
      const replaceAnalyticsSubPage = (path: string, subPage?: AnalyticsSubPages) =>
        path.replace(analyticsPageOptionalParam, subPage || '')

      return {
        path: `${this.path}/${AdminDashboardPages.Analytics}/${analyticsPageOptionalParam}`,
        getRoute(adminId: string, analyticsPage?: AnalyticsSubPages) {
          return replaceAdminId(replaceAnalyticsSubPage(this.path, analyticsPage), adminId)
        },

        get commute() {
          const baseCommutePath = replaceAnalyticsSubPage(this.path, AnalyticsSubPages.Commute)
          const commuteAnalyticsPageOptionalParam = createPathParam(AdminSubPagePathsParams.CommuteAnalyticsPage, {
            subPageValues: Object.values(CommuteAnalyticsTabViews),
            isOptional: true
          })

          return {
            path: `${baseCommutePath}/${commuteAnalyticsPageOptionalParam}`,
            getRoute(adminId: string, commuteAnalyticsTab?: string) {
              return replaceAdminId(this.path, adminId).replace(
                `${!commuteAnalyticsTab ? '/' : ''}${commuteAnalyticsPageOptionalParam}`,
                commuteAnalyticsTab || ''
              )
            }
          }
        },
        get modeling() {
          const baseModelingPath = replaceAnalyticsSubPage(this.path, AnalyticsSubPages.Modeling)
          return {
            path: baseModelingPath,
            getRoute(adminId: string) {
              return replaceAdminId(this.path, adminId)
            },
            get mapView() {
              const availablePages = Object.values(ModelingAnalyticsPages)
              const modelingPageParam = createPathParam(AdminSubPagePathsParams.CommuteAnalyticsPage, {
                subPageValues: availablePages
              })
              return {
                path: `${this.path}/${modelingPageParam}`,
                getRoute(adminId: string, modelingPage: string) {
                  if (!availablePages.join('|').includes(modelingPage)) {
                    logger.warn('Invalid modeling page provided', modelingPage)
                  }
                  return replaceAdminId(this.path, adminId).replace(modelingPageParam, modelingPage || '')
                }
              }
            }
          }
        },
        get report() {
          return {
            path: replaceAnalyticsSubPage(this.path, AnalyticsSubPages.Report),
            getRoute: this.getRoute,
            get details() {
              const reportIdParam = createPathParam(AdminDashboardParams.AnalyticReportId)
              return {
                path: `${this.path}/${reportIdParam}`,
                getRoute(adminId: string, id: string): string {
                  return replaceAdminId(this.path, adminId).replace(reportIdParam, id)
                }
              }
            }
          }
        },
        get surveys() {
          return {
            path: replaceAnalyticsSubPage(this.path, AnalyticsSubPages.Surveys),
            getRoute: this.getRoute,
            get details() {
              const surveyIdParam = createPathParam(AdminDashboardParams.SurveyId)
              return {
                path: `${this.path}/${surveyIdParam}`,
                getRoute(adminId: string, id: string): string {
                  return replaceAdminId(this.path, adminId).replace(surveyIdParam, id)
                }
              }
            }
          }
        },
        get datasets() {
          return { path: replaceAnalyticsSubPage(this.path, AnalyticsSubPages.Datasets), getRoute: this.getRoute }
        }
      }
    },

    get billing() {
      return {
        path: `${this.path}/${AdminDashboardPages.Billing}`,
        getRoute(id: string): string {
          return replaceAdminId(this.path, id)
        },
        get payment() {
          return {
            path: `${this.path}/${AdminBillingPages.Payment}`,
            getRoute(id: string): string {
              return replaceAdminId(this.path, id)
            },
            get add() {
              return {
                path: `${this.path}/${AdminBillingPages.AddPayment}`,
                getRoute(id: string): string {
                  return replaceAdminId(this.path, id)
                }
              }
            }
          }
        },
        get topups() {
          return {
            path: `${this.path}/${AdminBillingPages.TopUps}`,
            getRoute(id: string): string {
              return replaceAdminId(this.path, id)
            }
          }
        },
        get balance() {
          return {
            path: `${this.path}/${AdminBillingPages.Balance}`,
            getRoute(id: string): string {
              return replaceAdminId(this.path, id)
            }
          }
        },
        get invoices() {
          return {
            path: `${this.path}/${AdminBillingPages.Invoices}`,
            getRoute(id: string): string {
              return replaceAdminId(this.path, id)
            }
          }
        }
      }
    },

    get employees() {
      const availablePages = [AdminDashboardPages.Employees, AdminDashboardPages.Groups]
      const employeePageParam = createPathParam(AdminSubPagePathsParams.EmployeePage, { subPageValues: availablePages })
      const employeeOrGroupIdOptionalParam = createPathParam(AdminPathsParams.EmployeeOrGroupId, {
        pattern: uuidPattern,
        isOptional: true
      })
      return {
        path: `${this.path}/${employeePageParam}/${employeeOrGroupIdOptionalParam}`,
        getRoute(enterpriseId: string, page: AdminEmployeePages) {
          return `${AdminRouting.root.getRoute(enterpriseId)}/${page}`
        },
        get detail() {
          return {
            path: this.path.replace('?', ''),
            getRoute(adminId: string, page: AdminEmployeePages, id: string) {
              return `${AdminRouting.root.employees.getRoute(adminId, page)}/${id}`
            }
          }
        },
        get groups() {
          return {
            path: `${AdminRouting.root.path}/${AdminEmployeePages.Groups}`,
            getRoute(adminId: string) {
              return `${AdminRouting.root.getRoute(adminId)}/${AdminEmployeePages.Groups}`
            },
            get detail() {
              const groupIdParam = createPathParam(AdminPathsParams.GroupId, { pattern: uuidPattern })
              const groupPageOptionalParam = createPathParam(AdminSubPagePathsParams.GroupPage, {
                isOptional: true,
                subPageValues: ['members']
              })
              return {
                path: `${this.path}/${groupIdParam}/${groupPageOptionalParam}`,
                getRoute(adminId: string, groupId: string) {
                  return replaceAdminId(this.path, adminId)
                    .replace(groupIdParam, groupId)
                    .replace(`/${groupPageOptionalParam}`, '')
                }
              }
            },
            get members() {
              const groupIdParam = createPathParam(AdminPathsParams.GroupId, { pattern: uuidPattern })
              return {
                path: `${this.path}/${groupIdParam}/members`,
                getRoute(adminId: string, employeeId: string) {
                  return replaceAdminId(this.path, adminId).replace(groupIdParam, employeeId)
                }
              }
            }
          }
        },
        get map() {
          return {
            path: `${AdminRouting.root.path}/map`,
            getRoute(adminId: string) {
              return `${AdminRouting.root.getRoute(adminId)}/map`
            }
          }
        }
      }
    },

    get offices() {
      return {
        path: `${this.path}/${AdminDashboardPages.Offices}`,
        getRoute(adminId: string) {
          return replaceAdminId(this.path, adminId)
        }
      }
    },

    get enterprises() {
      return {
        path: `${this.path}/${AdminDashboardPages.Enterprises}`,
        getRoute: this.getRoute,
        get detail() {
          return {
            path: `${this.path}/:${AdminPathsParams.EnterpriseId}/:${AdminSubPagePathsParams.EnterprisePage}(members)?`,
            getRoute(adminId: string, enterpriseId: string) {
              return this.path
                .replace(`:${PublicPathsParams.AdminId}`, adminId)
                .replace(`:${AdminPathsParams.EnterpriseId}`, enterpriseId)
                .replace(`/:${AdminSubPagePathsParams.EnterprisePage}(members)?`, '')
            }
          }
        },
        get members() {
          return {
            path: `${this.path}/:${AdminPathsParams.EnterpriseId}/members`,
            getRoute(adminId: string, enterpriseId: string) {
              return this.path
                .replace(`:${PublicPathsParams.AdminId}`, adminId)
                .replace(`:${AdminPathsParams.EnterpriseId}`, enterpriseId)
            }
          }
        },
        get managers() {
          return {
            path: `${this.path}/:${AdminPathsParams.EnterpriseId}/managers`,
            getRoute(adminId: string, enterpriseId: string) {
              return this.path
                .replace(`:${PublicPathsParams.AdminId}`, adminId)
                .replace(`:${AdminPathsParams.EnterpriseId}`, enterpriseId)
            }
          }
        },
        get creation() {
          return {
            path: `${this.path}/create/:${AdminPathsParams.CreationStep}(${Object.values(
              AdminEnterpriseCreationPages
            ).join('|')})?`,
            getRoute(adminId: string) {
              return this.path
                .replace(`:${PublicPathsParams.AdminId}`, adminId)
                .replace(
                  `/:${AdminPathsParams.CreationStep}(${Object.values(AdminEnterpriseCreationPages).join('|')})?`,
                  ''
                )
            }
          }
        }
      }
    },

    get wallets() {
      const availablePages = `${AdminWalletPages.Wallets}|${AdminWalletPages.Enrollments}`
      return {
        path: `${this.path}/:${AdminSubPagePathsParams.WalletPage}(${availablePages})/:${AdminPathsParams.EnrolmentOrWalletId}?`,
        getRoute(adminId: string, page: AdminWalletPages) {
          return `${AdminRouting.root.getRoute(adminId)}/${page}`
        },
        get detail() {
          return {
            path: this.path.replace('?', ''),
            getRoute(adminId: string, page: AdminWalletPages, id: string) {
              return `${AdminRouting.root.wallets.getRoute(adminId, page)}/${id}`
            }
          }
        },
        get creation() {
          return {
            path: `${AdminRouting.root.path}/${AdminWalletPages.Wallets}/${ActionPages.Create}/:${
              AdminPathsParams.CreationStep
            }(${Object.values(AdminWalletCreationPages).join('|')})?`,
            getRoute(adminId: string) {
              return `${AdminRouting.root.getRoute(adminId)}/${AdminWalletPages.Wallets}/${ActionPages.Create}`
            }
          }
        }
      }
    },

    get announcements() {
      const availablePages = AdminAnnouncementPages.Announcements
      return {
        path: `${this.path}/:${AdminSubPagePathsParams.AnnouncementPage}(${availablePages})/:${AdminPathsParams.AnnouncementId}(${uuidPattern}|${ActionPages.Create})?`,
        getRoute(adminId: string, page: AdminAnnouncementPages) {
          return `${AdminRouting.root.getRoute(adminId)}/${page}`
        },
        get detail() {
          return {
            path: this.path.replace('?', ''),
            getRoute(adminId: string, page: AdminAnnouncementPages.Announcements, id: string) {
              return `${AdminRouting.root.announcements.getRoute(adminId, page)}/${id}`
            }
          }
        },
        get creation() {
          return {
            path: `${AdminRouting.root.path}/${AdminAnnouncementPages.Announcements}/${ActionPages.Create}`,
            getRoute(adminId: string) {
              return `${AdminRouting.root.getRoute(adminId)}/${AdminAnnouncementPages.Announcements}/${
                ActionPages.Create
              }`
            }
          }
        }
      }
    },

    get submissions() {
      return {
        path: `${this.path}/${AdminDashboardPages.Submissions}`,
        getRoute(adminId: string) {
          return this.path.replace(`:${PublicPathsParams.AdminId}`, adminId)
        },
        get detail() {
          return {
            path: `${this.path}/:${AdminPathsParams.SubmissionId}(${uuidPattern})`,
            getRoute(adminId: string, submissionId: string) {
              return this.path
                .replace(`:${PublicPathsParams.AdminId}`, adminId)
                .replace(`:${AdminPathsParams.SubmissionId}`, submissionId)
                .replace(`(${uuidPattern})`, '')
            }
          }
        }
      }
    },

    get plans() {
      const availablePages = `${AdminDashboardPages.Subscriptions}|${AdminDashboardPages.Plans}`
      return {
        path: `${this.path}/:${AdminSubPagePathsParams.PlanPage}(${availablePages})/:${AdminPathsParams.SubscriptionOrPlanId}(${uuidPattern})?`,
        getRoute(adminId: string, page: AdminPlansPages) {
          return `${AdminRouting.root.getRoute(adminId)}/${page}`
        },
        get detail() {
          return {
            path: this.path.replace('?', ''),
            getRoute(adminId: string, page: AdminPlansPages, id: string) {
              return `${AdminRouting.root.plans.getRoute(adminId, page)}/${id}`
            },
            get createWallet() {
              return {
                path: `${this.path}/wallets/create`,
                getRoute(adminId: string, page: AdminPlansPages, id: string): string {
                  return `${AdminRouting.root.plans.getRoute(adminId, page)}/${id}/wallets/create`
                }
              }
            },
            get selectWallets() {
              return {
                path: `${this.path}/wallets/select`,
                getRoute(adminId: string, page: AdminPlansPages, id: string): string {
                  return `${AdminRouting.root.plans.getRoute(adminId, page)}/${id}/wallets/select`
                }
              }
            },
            get groups() {
              return {
                path: `${this.path}/groups`,
                getRoute(adminId: string, page: AdminPlansPages, id: string): string {
                  return `${AdminRouting.root.plans.getRoute(adminId, page)}/${id}/groups`
                }
              }
            }
          }
        }
      }
    },
    get settings() {
      const availablePages = `${AdminDashboardPages.Integrations}|${AdminDashboardPages.Managers}`
      return {
        path: `${this.path}/:${AdminSubPagePathsParams.SettingsPage}(${availablePages})`,
        getRoute(adminId: string, page: AdminSettingsPages) {
          return `${AdminRouting.root.getRoute(adminId)}/${page}`
        }
      }
    },
    get guides() {
      return {
        path: `${this.path}/${AdminDashboardPages.CommuteSections}`,
        getRoute(adminId: string): string {
          return replaceAdminId(this.path, adminId)
        },
        get detail() {
          const guideIdParam = createPathParam(AdminPathsParams.CommuteSectionCategory, { pattern: uuidPattern })
          return {
            path: `${this.path}/${guideIdParam}`,
            getRoute(adminId: string, guideId: string): string {
              return replaceAdminId(this.path, adminId).replace(guideIdParam, guideId)
            }
          }
        }
      }
    },
    get surveys() {
      return {
        path: `${this.path}/${AdminDashboardPages.Surveys}`,
        getRoute(adminId: string) {
          return replaceAdminId(this.path, adminId)
        },
        get details() {
          const surveyIdParam = createPathParam(AdminPathsParams.SurveyId, {
            pattern: `${uuidPattern}|${ActionPages.Create}`
          })
          const creationPageOptionalParam = createPathParam(AdminSubPagePathsParams.SurveyCreationPage, {
            subPageValues: Object.values(AdminSurveyCreationPages),
            isOptional: true
          })
          return {
            path: `${this.path}/${surveyIdParam}/${creationPageOptionalParam}`,
            getRoute(adminId: string, surveyId: string, creationPage?: AdminSurveyCreationPages) {
              return replaceAdminId(this.path, adminId)
                .replace(surveyIdParam, surveyId)
                .replace(`${!creationPage ? '/' : ''}${creationPageOptionalParam}`, creationPage || '')
            }
          }
        }
      }
    }
  },

  get basePage() {
    // Add all sub menu pages here with concatenation when the time has come to have many
    // menu items with sub-pages
    const availablePages = Object.values(AnalyticsSubPages).join('|')
    return {
      path: `${this.root.path}/:${AdminPathsParams.MenuPage}/:${AdminPathsParams.SubMenuPage}(${availablePages})?`,
      getRoute(adminId: string, page: string, subPage?: string): string {
        return `/admins/${adminId}/${page}${subPage ? `/${subPage}` : ''}`
      }
    }
  }
}
