import { withScope } from '@sentry/react'
import axios, { AxiosError } from 'axios'
import axiosRetry from 'axios-retry'
import { clone } from 'lodash'
import { AxiosHttpClient, apiInstance, queryParamsSerializer } from '@commutifi-fe/commutifi-specific/api/axios'
import { CANCELED_API_REQ, LocalesType, getSupportedLocale } from '@commutifi-fe/constants'
import { CommutifiErrorData } from '@commutifi-fe/interfaces'
import { DEFAULT_LOCALE } from 'constants/settings'
import config from 'config/index'
import {
  destroyMessage,
  displayErrorRetryLoading,
  displayFailedRetryError
} from 'shared/components/common/RecurringError'
import { captureError } from 'utils/helpers/errors'
import sessionStorage from 'utils/sessionStorage'
import { logger } from 'utils/logRocket'
import storage from 'utils/storage'
import { ACCOUNT_ID_IDENTIFIER } from './constants'

export { ACCOUNT_ID_IDENTIFIER, JSON_HEADER, cancelMessage } from './constants'

// To support old name
export { apiInstance as apiCall }

export const { CancelToken } = axios
export const AxiosHttpInstance = new AxiosHttpClient({
  withCredentials: true,
  baseURL: config.dashboardApiUrl,
  headers: { 'Accept-Language': DEFAULT_LOCALE },
  timeout: 60 * 60 * 1000, // 1 minute
  paramsSerializer: queryParamsSerializer
})
export const dashboardApiServer = AxiosHttpInstance.getClient()

/**
 * Setup axios retry
 */
if (process.env.NODE_ENV !== 'test') {
  axiosRetry(dashboardApiServer, {
    retries: 3,
    retryDelay: (retryCount, error) => {
      displayErrorRetryLoading(error.config?.url || `${retryCount}`, retryCount)
      return Math.min(1000 * 2 ** retryCount, 30000)
    },
    // Skipping 500s as they are legit errors that we most likely can't recuperate from
    retryCondition: (error) => Boolean(error.response?.status && error.response.status > 500)
  })
}

export const arcApiServer = axios.create({
  baseURL: config.arcApiUrl,
  headers: { 'Ocp-Apim-Subscription-Key': config.arcSubscriptionKey || '' }
})

/**
 * We use the session storage when the user don't want to stay logged in so when the user closes the tab is session will be ended.
 * In all other cases we want to persist the user session so we store the id in the local storage
 */
export const saveAuthenticated = (accountId: string, options: { stayLoggedIn?: boolean } = {}) => {
  if (options.stayLoggedIn === false) {
    sessionStorage.put(ACCOUNT_ID_IDENTIFIER, accountId)
  } else {
    storage.put(ACCOUNT_ID_IDENTIFIER, accountId)
  }
}

/**
 * Tries to access the local storage to see if we have the account id stored. That means we can assume the user
 * is logged in with our backend.
 * We need to fallback to the session storage cause we can have users that chose the option to NOT stay logged in.
 * In that case we use the local storage so when the user closes the tab is session will be ended
 */
export const getAuthenticatedAccountId = () =>
  storage.get(ACCOUNT_ID_IDENTIFIER) || sessionStorage.get(ACCOUNT_ID_IDENTIFIER)

export const clearAuthenticated = () => {
  storage.remove(ACCOUNT_ID_IDENTIFIER)
  document.cookie.split(';').forEach((c) => {
    document.cookie = c.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`)
  })
  sessionStorage.clear()
}

export const setLocaleHeader = (locale: LocalesType | `${LocalesType}`) => {
  dashboardApiServer.defaults.headers['Accept-Language'] = getSupportedLocale(locale)
}

export const setHeader = (key: string, value: any) => {
  dashboardApiServer.defaults.headers[key] = value
}
export const getHeader = (key: string) => dashboardApiServer.defaults.headers[key]

const handleErrorCapture = (error: AxiosError<CommutifiErrorData>) => {
  try {
    if (error.response) {
      withScope((scope) => {
        scope.setTag('HTTP', error.config?.method)
        const endpoint = error.response?.config.url ? error.response.config.url.split('?')[0] : undefined
        if (endpoint) scope.setTag('Endpoint', endpoint)

        // Here we are trying to group endpoints so it is easy to see different grouped errors on sentry and logRocket.
        // We also add a lot of extra data to identify what was the cause of the error
        const errorFingerprint = endpoint
          ? [
              error.response?.config.method || '',
              endpoint,
              String(error.response?.config.data?.code ?? error.response?.status)
            ]
          : undefined
        if (errorFingerprint) {
          scope.setFingerprint(errorFingerprint)
        }

        // Capture error on Sentry and add LogRocket session URL if available
        captureError(error, {
          extra: {
            url: error.response?.config.url,
            data: error.response?.config.data,
            status: error.response?.status,
            httpCode: error.response?.config.data?.httpCode,
            commutifiCode: error.response?.data.code,
            error: error.request.response || error.request.statusText,
            response: error.request.responseText
          }
        })
      })
    }
    if (error.request) {
      withScope((scope) => {
        scope.setTag('HTTP', 'networkError')
        captureError(error, {
          extra: {
            url: error.request.responseURL,
            status: error.request.status,
            response: error.request.response,
            error: error.request.response || error.request.statusText
          }
        })
      })
    }
  } catch (e) {
    logger.warn('Sentry is probably not loaded. Error: ', e)
  }
}

export const apiDashboardServer = apiInstance(dashboardApiServer, {
  onSuccess: (res) => {
    if (res.config['axios-retry']?.retryCount && res.config['axios-retry'].retryCount <= 3) {
      res.config.url && destroyMessage(res.config.url)
    }
  },
  onError: (error) => {
    handleErrorCapture(error)
    if (error.config?.['axios-retry']?.retryCount && error.config['axios-retry'].retryCount >= 3) {
      displayFailedRetryError(error)
    }

    if (axios.isCancel(clone(error))) {
      logger.log('Request canceled: ', error.message)
      return CANCELED_API_REQ
    }
    return undefined
  }
})
