import React from 'react'
import type { IntlFormatters, IntlShape as UntypedIntlShape } from 'react-intl'
import { FormattedMessage as ReactIntlFormattedMessage, useIntl as useReactIntl } from 'react-intl'
import type { Props as ReactIntlFormattedMessageProps } from 'react-intl/src/components/message'
import en from './en'

export {
  createIntl,
  createIntlCache,
  defineMessage,
  defineMessages,
  FormattedDate,
  FormattedDateParts,
  FormattedDateTimeRange,
  FormattedDisplayName,
  FormattedList,
  FormattedListParts,
  FormattedNumber,
  FormattedNumberParts,
  FormattedPlural,
  FormattedRelativeTime,
  FormattedTime,
  FormattedTimeParts,
  injectIntl,
  IntlContext,
  IntlProvider,
  InvalidConfigError,
  MessageFormatError,
  MissingDataError,
  MissingTranslationError,
  RawIntlProvider,
  ReactIntlError,
  ReactIntlErrorCode,
  UnsupportedFormatterError
} from 'react-intl'
export type {
  CustomFormatConfig,
  CustomFormats,
  FormatDateOptions,
  FormatDisplayNameOptions,
  FormatListOptions,
  FormatNumberOptions,
  FormatPluralOptions,
  FormatRelativeTimeOptions,
  Formatters,
  IntlCache,
  IntlConfig,
  IntlFormatters,
  IntlShape as UntypedIntlShape,
  MessageDescriptor,
  MessageFormatElement,
  ResolvedIntlConfig,
  WithIntlProps,
  WrappedComponentProps
} from 'react-intl'

const messages = {
  en: () =>
    new Promise<{ default: typeof en }>((resolve) => {
      resolve({ default: en })
    }),
  fr: () => import('./fr'),
  cs: () => import('./cs'),
  'de-DE': () => import('./de-DE'),
  'es-ES': () => import('./es-ES'),
  'it-IT': () => import('./it-IT'),
  'nl-NL': () => import('./nl-NL'),
  pl: () => import('./pl'),
  'pt-PT': () => import('./pt-PT')
}
export { messages }
export { en as defaultMessages }

export type IntlMessageKeys = keyof typeof en

// The arguments to the original formatMessage function.
type FormatMessageArgs = Parameters<IntlFormatters['formatMessage']>

// Extend the original FormattedMessage props.
type FormattedMessageProps = ReactIntlFormattedMessageProps<Record<string, React.ReactNode>> & {
  id?: IntlMessageKeys
}

function FormattedMessage({ id, ...rest }: FormattedMessageProps) {
  return <ReactIntlFormattedMessage id={id} {...rest} />
}

type IntlShape = UntypedIntlShape & {
  formatMessage(
    descriptor: FormatMessageArgs[0] & {
      id?: IntlMessageKeys
    },
    values?: FormatMessageArgs[1],
    options?: FormatMessageArgs[2]
  ): string
  formatMessage(
    descriptor: FormatMessageArgs[0] & {
      id?: IntlMessageKeys
    },
    values?: FormatMessageArgs[1],
    options?: FormatMessageArgs[2]
  ): Array<React.ReactNode>
}

function useIntl(): IntlShape {
  return useReactIntl()
}
export { useIntl, FormattedMessage, IntlShape }
