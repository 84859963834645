import { useQuery } from '@tanstack/react-query'
import { GetResponse } from '@commutifi-fe/interfaces'
import { CommuterPlansRepository } from '../../Domain/Repositories/CommuterPlans'
import { IApiClient } from '../../Domain/Repositories/Http'
import { ListPlan } from '../../Domain/Models/CommuterPlans'

export function PlansSuggestionsRepositoryImpl<TQueryParams extends Record<string, any>>(
  url: string,
  httpClient: IApiClient
): CommuterPlansRepository<TQueryParams> {
  return {
    usePlansSuggestions: (req) => {
      const { queryParams, queryOptions } = req || {}
      return useQuery({
        queryKey: ['plans/suggestions', queryParams],
        queryFn: () => httpClient.get<GetResponse<ListPlan>>(url, { params: queryParams }),
        select: (data) => data?.records,
        ...queryOptions
      })
    }
  }
}
