import { createElement, useReducer, useMemo, CSSProperties, ReactNode, Dispatch } from 'react'
import cx from 'classnames'
import { useDeepCompareEffect } from '@commutifi-fe/hooks'
import { helpEmail, HttpStatusCode } from '@commutifi-fe/constants'
import ErrorCompass from '@commutifi-fe/custom-icons/svgs/Compass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../AppButton'
import { useLocaleReceiver } from '../../locales/LocaleReceiver'
import { colors } from '../../style/constants'
import Error403 from './error-403.png'
import './styles.scss'

export interface GenericErrorProps {
  /**
   * Error object with status so we can infer the type of error and show the right
   * title icon, description, etc.
   */
  error?: unknown
  /**
   * Type of the error. Page means we will display a background image and a button to
   * return home by default. Component won't have those by default but you can define actions
   * prop with a button if you want
   */
  type?: 'page' | 'component'
  /**
   * Override the error state icon with a custom icon
   */
  icon?: ReactNode
  /**
   * When you need to hide the error image/icon set this boolean to true
   */
  hideErrorIcon?: boolean
  /**
   * Overwrite the error title. You can set it to an empty string to hide it
   */
  title?: ReactNode
  /**
   * Overwrite the text on the button
   */
  buttonText?: ReactNode
  /**
   * Overwrite the error description. You can set it to an empty string to hide it
   */
  description?: ReactNode
  /**
   * Define your own component(s) to have custom actions
   * (ex. instead of the default button that redirect the user you can define a button to reload the page)
   */
  actions?: ReactNode
  /**
   * Element to use to build the lik on top of the button to redirect the user
   */
  linkElement?: string
  /**
   * Redirect url
   */
  redirect?: string
  /**
   * Style object to apply to the component
   */
  style?: CSSProperties
  /**
   * Class name to apply to the component
   */
  className?: string
  /**
   * Whether to use compact mode which display the error on limited space
   */
  compact?: boolean
}

interface ErrorStateProps {
  img?: ReactNode
  title?: ReactNode
  description?: ReactNode
}

enum TypeKeys {
  SetError = 'set_error'
}

interface ErrorAction {
  type: TypeKeys
  payload: ErrorStateProps
}

type ErrorConfigKeyType = 404 | 403 | 500 | 0

/**
 * Link component for contacting support.
 *
 * This component is used to display the link to contact support. It will use the
 * default email address from the helpEmail constant.
 */
function ContactUsLink({ children }: { children: ReactNode }) {
  return (
    <a href={`mailto:${helpEmail}`} target="__blank">
      {children}
    </a>
  )
}

/**
 * Error description component.
 *
 * This component is used to display the error description. It will use the default
 * description if no description is provided.
 */
function ErrorDescription({ description, children }: { description?: ReactNode; children?: ReactNode }) {
  const intl = useLocaleReceiver('GenericError')
  return (
    <div className="app-generic-error-description">
      <span>
        {description ||
          intl.formatMessage(
            { id: 'description' },
            {
              contactUsLink: (
                <ContactUsLink key="error-contact-us">{intl.formatMessage({ id: 'contactUs' })}</ContactUsLink>
              )
            }
          )}
        {children}
      </span>
    </div>
  )
}

/**
 * Error icon component.
 * Compact icon will be limited in size by our stylesheet and if no icon is provided
 * we will use the default circle exclamation icon from FontAwesome.
 *
 * If it's not compact we simply use the original icon, if provided, or one of our known error image.
 */
function ErrorIcon({ icon, compact, errorImg }: { icon?: ReactNode; compact?: boolean; errorImg: ReactNode }) {
  if (compact) {
    return (
      <span className="app-generic-error-icon">
        {icon || <FontAwesomeIcon icon={faCircleExclamation} color={colors.feedbackDestructive400} size="1x" />}
      </span>
    )
  }

  if (icon) {
    return <div>{icon}</div>
  }

  return errorImg
}

/**
 * Error actions component
 */
function ErrorActions({
  type,
  actions,
  linkElement = 'a',
  redirect = '/',
  buttonText
}: {
  type: 'page' | 'component'
  actions?: ReactNode
  linkElement?: string
  redirect?: string
  buttonText?: ReactNode
}) {
  const intl = useLocaleReceiver('GenericError')

  if (actions) {
    return actions
  }

  /**
   * Show button only on Page errors. This could be changed if we have use cases for
   * component errors with a default action button.
   */
  if (type === 'page') {
    return createElement(
      linkElement,
      {
        to: redirect,
        href: redirect
      },
      <Button size="middle" type="primary" className="u-margin-top--large">
        <span>{buttonText || intl.formatMessage({ id: 'goBackHomeButton' })}</span>
      </Button>
    )
  }

  return null
}

/**
 * Error content component
 */
function ErrorContent({
  title,
  description,
  compact
}: {
  title?: ReactNode
  description?: ReactNode
  compact?: boolean
}) {
  return (
    <>
      <div className={cx('u-flex-column', !compact && 'u-margin-top--large')}>
        {title ? <span className="app-generic-error-text">{title}</span> : null}
        {compact && description ? <span className="app-generic-error-description--compact">{description}</span> : null}
      </div>

      {!compact && description ? <span className="app-generic-error-description">{description}</span> : null}
    </>
  )
}

function errorReducer(state: ErrorStateProps, action: ErrorAction): ErrorStateProps {
  const { type, payload } = action
  let changes
  switch (type) {
    case TypeKeys.SetError: {
      const { img, title, description } = payload
      changes = { img, title, description }
      break
    }
    default: {
      throw new Error(`Unhandled errorReducer action type: ${type as any}`)
    }
  }
  return { ...state, ...changes }
}

export const useError = (
  error: any,
  { title, description }: { title?: ReactNode; description?: ReactNode }
): [ErrorStateProps, Dispatch<ErrorAction>] => {
  const intl = useLocaleReceiver('GenericError')
  const [state, dispatch] = useReducer(errorReducer, {})

  const errorTypeConfig: Record<ErrorConfigKeyType, ErrorStateProps> = useMemo(
    () => ({
      [HttpStatusCode.NotFound]: {
        img: <ErrorCompass height={80} />,
        title: intl.formatMessage({ id: 'error404Title' }),
        description: (
          <ErrorDescription
            description={intl.formatMessage(
              { id: 'error404Description' },
              {
                link: <ContactUsLink>{intl.formatMessage({ id: 'contactUs' })}</ContactUsLink>
              }
            )}
          >
            &nbsp;
            {intl.formatMessage({ id: 'refresh' })}
          </ErrorDescription>
        )
      },
      [HttpStatusCode.Forbidden]: {
        img: (
          <img
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- All good
            src={typeof Error403 === 'string' ? Error403 : Error403.src}
            className={cx('app-generic-error-icon', 'u-margin-bottom--medium')}
            alt="generic error"
          />
        ),
        title: intl.formatMessage({ id: 'error403Title' }),
        description: ''
      },
      [HttpStatusCode.InternalServerError]: {
        img: <ErrorCompass height={80} />,
        title: intl.formatMessage({ id: 'error500Title' }),
        description: <ErrorDescription />
      },
      [HttpStatusCode.Unknown]: {
        img: <ErrorCompass height={80} />,
        title: intl.formatMessage({ id: 'errorSorry' }),
        description: <ErrorDescription />
      }
    }),
    [intl]
  )

  useDeepCompareEffect(() => {
    const errorStatus: ErrorConfigKeyType =
      error && typeof error === 'object'
        ? error.response?.status || error.status || error.statusCode || error.httpCode
        : undefined
    const errorType: ErrorConfigKeyType = Object.prototype.hasOwnProperty.call(errorTypeConfig, errorStatus)
      ? errorStatus
      : HttpStatusCode.Unknown
    const errorConfig = errorTypeConfig[errorType]
    dispatch({
      type: TypeKeys.SetError,
      payload: {
        img: errorConfig.img,
        title: title || title === '' ? title : errorConfig.title,
        description: description || errorConfig.description
      }
    })
  }, [error, title])
  return [state, dispatch]
}

export function GenericError({
  error,
  type = 'component',
  hideErrorIcon = false,
  icon,
  title,
  buttonText,
  description,
  actions,
  linkElement = 'a',
  redirect = '/',
  style,
  className,
  compact
}: GenericErrorProps) {
  const [errorState] = useError(error, { title, description })

  return (
    <div
      data-testid="generic-error"
      className={cx(
        'app-generic-error',
        compact && 'app-generic-error--compact',
        type === 'page' && 'app-generic-error--full-page',
        className
      )}
      style={style}
    >
      <div className="app-generic-error-header">
        {!hideErrorIcon ? <ErrorIcon icon={icon} compact={compact} errorImg={errorState.img} /> : null}

        <ErrorContent title={errorState.title} description={errorState.description} compact={compact} />
      </div>

      <ErrorActions
        type={type}
        actions={actions}
        linkElement={linkElement}
        redirect={redirect}
        buttonText={buttonText}
      />
    </div>
  )
}
