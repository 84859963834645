import { captureException } from '@sentry/react'
import { ScopeContext } from '@sentry/types'
import { FormInstance, FormProps } from '@commutifi-fe/ui'
import { logger, captureException as loggerCapture, getSessionUrl } from 'utils/logRocket'

const HEADER_OFFSET = 100

export const captureError = (error: Error, { extra }: Partial<ScopeContext> = {}) => {
  // Capture error on logRocket
  loggerCapture(error, {
    extra
  } as Parameters<typeof loggerCapture>[1])

  // Capture error on Sentry and add LogRocket session URL if available
  captureException(error, {
    extra: {
      sessionUrl: getSessionUrl(),
      ...extra
    }
  })
}

/**
 * This function will make your window scroll to the element found in the
 * querySelector using the selector param.
 * The offset is set to scroll right under the main header
 * @param selector - query selector* to get the dom element.
 *
 * * https://developer.mozilla.org/en-US/docs/Web/API/Document/querySelector
 */
export const scrollToElement = (selector: string) => {
  const htmlElement = document.querySelector(selector)

  if (!htmlElement) {
    logger.error(`Element ${selector} not found in the document object`)
    return
  }

  const windowTop = window.pageYOffset || document.documentElement.scrollTop
  const elTopFromViewport = htmlElement.getBoundingClientRect().top || 0
  window.scrollTo({
    top: elTopFromViewport + windowTop - HEADER_OFFSET,
    behavior: 'smooth'
  })
}

export const formHasErrors = (form: FormInstance | Parameters<NonNullable<FormProps['onFieldsChange']>>[1]) =>
  Array.isArray(form)
    ? form.some((field) => field.errors && field.errors.length > 0)
    : form.getFieldsError().some((field) => field.errors?.length > 0)
