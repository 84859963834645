import { useCallback } from 'react'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { faFileLines, faSealExclamation, faToolbox } from '@fortawesome/pro-regular-svg-icons'
import {
  CommutifiIconDefinition,
  faBike,
  faBikeshare,
  faBus,
  faCarpool,
  faCarshare,
  faElectricPlug,
  faRemote,
  faRideshare,
  faTrain,
  faVanpool,
  faWalk,
  faScooter
} from '@commutifi-fe/custom-icons/solid'
import { faParking } from '@commutifi-fe/custom-icons/regular'
import { CommuteSectionCategory } from '../../Models/CommuteSection'
import { useComponentIntl } from '../../../locales'

export const commuteSectionIconByCategory: Record<string, CommutifiIconDefinition> = {
  [CommuteSectionCategory.Overview]: faFileLines,
  [CommuteSectionCategory.Bike]: faBike,
  [CommuteSectionCategory.Bikeshare]: faBikeshare,
  [CommuteSectionCategory.Carpool]: faCarpool,
  [CommuteSectionCategory.Carshare]: faCarshare,
  [CommuteSectionCategory.ElectricVehicle]: faElectricPlug,
  [CommuteSectionCategory.EmergencyRideHome]: faSealExclamation,
  [CommuteSectionCategory.EquipmentRental]: faToolbox,
  [CommuteSectionCategory.Micromobility]: faScooter,
  [CommuteSectionCategory.Parking]: faParking,
  [CommuteSectionCategory.Remote]: faRemote,
  [CommuteSectionCategory.Shuttle]: faBus,
  [CommuteSectionCategory.TaxiAndRideshare]: faRideshare,
  [CommuteSectionCategory.Transit]: faTrain,
  [CommuteSectionCategory.Vanpool]: faVanpool,
  [CommuteSectionCategory.Walk]: faWalk,
  [CommuteSectionCategory.Miscellaneous]: faPaperclip
}
export function getCommuteSectionIcon(commuteSectionCategory?: CommuteSectionCategory | null) {
  const defaultIcon = faPaperclip
  if (!commuteSectionCategory) {
    return defaultIcon
  }
  return commuteSectionIconByCategory[commuteSectionCategory] || defaultIcon
}

export const commuteSectionTranslationKeyByCategory = {
  [CommuteSectionCategory.Overview]: 'commuteSections.categories.overview' as const,
  [CommuteSectionCategory.Bike]: 'commuteSections.categories.bicycling' as const,
  [CommuteSectionCategory.Bikeshare]: 'commuteSections.categories.bikesharing' as const,
  [CommuteSectionCategory.Carpool]: 'commuteSections.categories.carpooling' as const,
  [CommuteSectionCategory.Carshare]: 'commuteSections.categories.carsharing' as const,
  [CommuteSectionCategory.ElectricVehicle]: 'commuteSections.categories.electricVehicle' as const,
  [CommuteSectionCategory.EmergencyRideHome]: 'commuteSections.categories.emergencyRide' as const,
  [CommuteSectionCategory.EquipmentRental]: 'commuteSections.categories.equipmentRental' as const,
  [CommuteSectionCategory.Micromobility]: 'commuteSections.categories.micromobility' as const,
  [CommuteSectionCategory.Parking]: 'commuteSections.categories.parking' as const,
  [CommuteSectionCategory.Remote]: 'commuteSections.categories.remote' as const,
  [CommuteSectionCategory.Shuttle]: 'commuteSections.categories.shuttle' as const,
  [CommuteSectionCategory.TaxiAndRideshare]: 'commuteSections.categories.taxiAndRideshare' as const,
  [CommuteSectionCategory.Transit]: 'commuteSections.categories.transit' as const,
  [CommuteSectionCategory.Vanpool]: 'commuteSections.categories.vanpool' as const,
  [CommuteSectionCategory.Walk]: 'commuteSections.categories.walking' as const,
  [CommuteSectionCategory.Miscellaneous]: 'commuteSections.categories.miscellaneous' as const
}

export const getCommuteSectionTranslationKey = (commuteSectionCategory?: CommuteSectionCategory | null) => {
  const defaultKey = 'commuteSections.categories.miscellaneous'
  if (!commuteSectionCategory) {
    return defaultKey
  }

  return (
    commuteSectionTranslationKeyByCategory[
      commuteSectionCategory as keyof typeof commuteSectionTranslationKeyByCategory
    ] || defaultKey
  )
}

export const useCommuteSectionTranslation = () => {
  const intl = useComponentIntl('CommuteSections')

  const memoizedGetCommuteSectionTranslation = useCallback(
    (commuteSectionCategory?: CommuteSectionCategory | null) =>
      intl.formatMessage({ id: getCommuteSectionTranslationKey(commuteSectionCategory) }),
    [intl]
  )

  return memoizedGetCommuteSectionTranslation
}
