import axios from 'axios'
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { get, clone } from 'lodash'
import type { Store } from 'redux'
import type { Overwrite } from 'utility-types'
import fetchIntercept from 'fetch-intercept'
import { dashboardApiServer, getAuthenticatedAccountId } from 'api/index'
import { refreshAccessToken } from 'api/modules/authentication'
import { UNAUTHORIZED_ERROR_CODES, HttpStatusCode } from '@commutifi-fe/constants'
import { logout, unauthorizeUser } from 'store/modules/auth/actions'
import type { RootState } from 'store/index'

const hasToken = () => Boolean(getAuthenticatedAccountId())

export default {
  setupInterceptors: (store: Store<RootState>) => {
    dashboardApiServer.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: Overwrite<AxiosError, { config: AxiosRequestConfig & { isRetry?: boolean } }>) => {
        if (axios.isCancel(clone(error))) {
          return Promise.reject(error)
        }

        const doLogout = () => {
          // this dispatch, generate a first redirection to /auth
          // check how we can avoid that
          store.dispatch(logout({ error: UNAUTHORIZED_ERROR_CODES.ExpiredToken }))
          return Promise.reject(error)
        }

        const { config: originalRequest, response } = error

        // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check -- A
        switch (get(response, 'status')) {
          case HttpStatusCode.Unauthorized:
            if (!hasToken() && !store.getState().auth.loggedIn) {
              // User is not logged in, let the error go through
              // because those errors are handled by protected routes
              // and we want the user to be able to access public routes
              // even if there are not authenticated
              return Promise.reject(error)
            }

            if (originalRequest.isRetry || originalRequest.url?.includes('/authentication/token/refresh')) {
              return doLogout()
            }

            // If the user is not authenticated we redirect him to the auth page so he can enter
            // his credentials to login
            // We just want to continue if the user is authenticated and his token expired
            if (!hasToken()) {
              store.dispatch(unauthorizeUser({ error: UNAUTHORIZED_ERROR_CODES.Unauthorized }))
              return Promise.reject(error)
            }

            await refreshAccessToken()

            originalRequest.isRetry = true
            return dashboardApiServer(originalRequest)
          default:
            break
        }

        return Promise.reject(error)
      }
    )

    const cache = new Map<string, { config: Request; createdAt: number }>()
    fetchIntercept.register({
      request: (url, config) => {
        cache.forEach((entry, cacheUrl) => {
          // 30 sec TTL, making sure we can access them during an intercepted 401 but
          // also making sure we don't bust the memory over time
          if (Math.abs(entry.createdAt - Math.floor(Date.now() / 1000)) > 30) {
            cache.delete(cacheUrl)
          }
        })

        if (url && config && !cache.get(url)) {
          cache.set(url, { config, createdAt: Math.floor(Date.now() / 1000) })
        }

        return [url, config]
      },
      // @ts-expect-error -- Not sure why it's the wrong type
      response: (res) => {
        const originUrl = res.request.url
        const doLogout = () => {
          // this dispatch, generate a first redirection to /auth
          // check how we can avoid that
          store.dispatch(logout({ error: UNAUTHORIZED_ERROR_CODES.ExpiredToken }))
          throw new Error('Unauthorized User')
        }

        const { request: originalRequest } = res

        switch (get(res, 'status')) {
          case HttpStatusCode.Unauthorized: {
            if (!hasToken() && !store.getState().auth.loggedIn) {
              // User is not logged in, let the error go through
              // because those errors are handled by protected routes
              // and we want the user to be able to access public routes
              // even if there are not authenticated
              throw new Error('User is not logged In')
            }

            // TODO handle break retry loop
            if (
              originalRequest.headers.get('x-commutifi-retry') === 'true' ||
              originalRequest.url.includes('/authentication/token/refresh')
            ) {
              doLogout()
              return res
            }

            // If the user is not authenticated we redirect him to the auth page so he can enter
            // his credentials to login
            // We just want to continue if the user is authenticated and his token expired
            if (!hasToken()) {
              store.dispatch(unauthorizeUser({ error: UNAUTHORIZED_ERROR_CODES.Unauthorized }))
              throw new Error('Unauthorized user')
            }

            return refreshAccessToken().then(() => {
              if (!cache.get(originUrl)?.config) {
                throw new Error('Missing original request config')
              }

              return fetch(originalRequest.url, {
                ...cache.get(originUrl)?.config,
                headers: { ...originalRequest.headers, 'x-commutifi-retry': 'true' }
              }).then((fetchRes) => {
                cache.delete(originUrl)
                return fetchRes
              })
            })
          }
          default:
            break
        }

        return res
      }
    })
  }
}
