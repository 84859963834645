import { Factors } from '@commutifi-fe/constants/metrics'
import { IconClusterLayerProps } from '@commutifi-fe/deck.gl/components/IconClusterLayer'
import { IconMapping } from '@commutifi-fe/deck.gl/layers'
import iconAtlas from '@commutifi-fe/deck.gl/sprites/commuteModes/sprite.png'
import iconSpriteData from '@commutifi-fe/deck.gl/sprites/commuteModes/sprite.json'

export { iconAtlas }
export const iconMapping = iconSpriteData.sprites.reduce<NonNullable<IconMapping>>((acc, sprite) => {
  acc[sprite.fileName.replace(/\.[^/.]+$/, '')] = { ...sprite, mask: false }
  return acc
}, {})

export const FACTOR_THRESHOLD_CATEGORIES = {
  veryBad: 'veryBad',
  bad: 'bad',
  ok: 'ok',
  good: 'good',
  veryGood: 'veryGood'
}
export const CATEGORIES_COLORS = {
  [FACTOR_THRESHOLD_CATEGORIES.veryBad]: '#EF6666',
  [FACTOR_THRESHOLD_CATEGORIES.bad]: '#F2BB74',
  [FACTOR_THRESHOLD_CATEGORIES.ok]: '#F4CD7A',
  [FACTOR_THRESHOLD_CATEGORIES.good]: '#D1D47E',
  [FACTOR_THRESHOLD_CATEGORIES.veryGood]: '#98C491'
}

export const FACTOR_THRESHOLDS_CATEGORIES = {
  [Factors.Time]: {
    '0-1200': FACTOR_THRESHOLD_CATEGORIES.veryGood,
    '1200-2400': FACTOR_THRESHOLD_CATEGORIES.good,
    '2400-3600': FACTOR_THRESHOLD_CATEGORIES.ok,
    '3600-4800': FACTOR_THRESHOLD_CATEGORIES.bad,
    4800: FACTOR_THRESHOLD_CATEGORIES.veryBad
  },
  [Factors.Cost]: {
    '0-1': FACTOR_THRESHOLD_CATEGORIES.veryGood,
    '1-3': FACTOR_THRESHOLD_CATEGORIES.good,
    '3-8': FACTOR_THRESHOLD_CATEGORIES.ok,
    '8-15': FACTOR_THRESHOLD_CATEGORIES.bad,
    15: FACTOR_THRESHOLD_CATEGORIES.veryBad
  },
  [Factors.Carbon]: {
    '0-0.907185': FACTOR_THRESHOLD_CATEGORIES.veryGood,
    '0.907185-2.72155': FACTOR_THRESHOLD_CATEGORIES.good,
    '2.72155-5.44311': FACTOR_THRESHOLD_CATEGORIES.ok,
    '5.44311-9.07185': FACTOR_THRESHOLD_CATEGORIES.bad,
    9.07185: FACTOR_THRESHOLD_CATEGORIES.veryBad
  },
  [Factors.Score]: {
    80: FACTOR_THRESHOLD_CATEGORIES.veryGood,
    '60-80': FACTOR_THRESHOLD_CATEGORIES.good,
    '40-60': FACTOR_THRESHOLD_CATEGORIES.ok,
    '20-40': FACTOR_THRESHOLD_CATEGORIES.bad,
    '0-20': FACTOR_THRESHOLD_CATEGORIES.veryBad
  }
}

export const FACTOR_START_RANGES = {
  [Factors.Time]: [0, 1200, 2400, 3600, 4800],
  [Factors.Cost]: [0, 1, 3, 8, 15],
  [Factors.Carbon]: [0, 0.907185, 2.72155, 5.44311, 9.07185],
  [Factors.Score]: [0, 20, 40, 60, 80]
}

export const FACTORS_MAX = {
  [Factors.Score]: 100
}

// Mapbox absolutely want a number literal to be defined for each step
// so we set the thresholds manually for each factor
export const MAPBOX_COLOR_STEPS = {
  [Factors.Time]: [
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.veryGood],
    1200,
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.good],
    2400,
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.ok],
    3600,
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.bad],
    4800,
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.veryBad]
  ],
  [Factors.Cost]: [
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.veryGood],
    1,
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.good],
    3,
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.ok],
    8,
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.bad],
    15,
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.veryBad]
  ],
  [Factors.Carbon]: [
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.veryGood],
    0.907185,
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.good],
    2.72155,
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.ok],
    5.44311,
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.bad],
    9.07185,
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.veryBad]
  ],
  [Factors.Score]: [
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.veryBad],
    20,
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.bad],
    40,
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.ok],
    60,
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.good],
    80,
    CATEGORIES_COLORS[FACTOR_THRESHOLD_CATEGORIES.veryGood]
  ]
}

// Radius of circles created for heatmap layer
export const HM_CLUSTER_RADIUS = 35
export const HM_MAX_ZOOM = 13
// Radius of circles created for heatmap data points count layer
export const HM_COUNTS_CLUSTER_RADIUS = 100

export const HM_SINGLE_POINT_ZOOM = 11
export const HM_SINGLE_POINT_RADIUS = 3

// Heatmap circle colors layer fading effect
export const HM_CIRCLE_OPACITY = 0.85
export const HM_CIRCLE_SEE_THROUGH_OPACITY = 0.225
export const HM_CIRCLE_BLUR = 0.65

export const HM_STROKE_WIDTH = 1.5
export const HM_TEXT_COLOR = '#23292f'

export enum MapType {
  Density = 'density',
  Points = 'points'
}

export enum MapMetrics {
  // Only for points map
  CommuteMode = 'mode',
  Cost = 'cost',
  Emissions = 'emissions',
  CommuteScore = 'commuteScore',
  Time = 'time'
}

export const DEFAULT_ICON_LAYER_PROPS: Partial<IconClusterLayerProps<any>> = {
  id: 'icon-layer',
  iconSizeScale: 30,
  /**
   * This is to stop generation the cluster layer starting at zoom Y.
   * NOTE that this seems to be off by 1 zoom level from the zoom level indicated
   *      by deck.gl (Ex. 9 here -> 10 on deck.gl)
   */
  clusterMaxZoom: 9,
  iconAtlas,
  iconMapping,
  parameters: {
    depthWriteEnabled: false
  }
}

export const ZoomThresholds = {
  WorldContinentView: 5,
  RegionView: 8,
  MetropolitanView: 10.5,
  CityView: 12,
  Zoomed: 13.25,
  Max: 15
}
