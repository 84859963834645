import { useCallback } from 'react'
import { RequestParams } from '@commutifi-fe/interfaces'
import { CommuteSectionsRepository, GetCommuteSectionsRequest } from '../../../Domain/Repositories/CommuteSection'
import { IGetCommuteSectionsUseCase } from '../../../Domain/UseCases/CommuteSection/IGetCommuteSectionsUseCase'
import { getCommuteSectionTranslationKey } from '../../../Domain/UseCases/CommuteSection/utils'

export function useCommuteSections<TQueryParams extends RequestParams>(
  repository: CommuteSectionsRepository<TQueryParams>,
  options?: {
    queryOptions?: GetCommuteSectionsRequest<TQueryParams>['queryOptions']
    queryParams?: TQueryParams
  }
): UseCommuteSectionsHook<TQueryParams> {
  const execute = useCallback(
    (req: GetCommuteSectionsRequest<TQueryParams> = {}) =>
      repository.useCommuteSections(
        options ? new GetCommuteSectionsRequest(options?.queryParams, options?.queryOptions) : req
      ),
    [options, repository]
  )

  return {
    execute,
    getCommuteSectionTranslationKey
  }
}

export interface UseCommuteSectionsHook<TQueryParams extends RequestParams>
  extends IGetCommuteSectionsUseCase<TQueryParams> {
  getCommuteSectionTranslationKey: typeof getCommuteSectionTranslationKey
}
